/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import HomeBottomNav from './Layouts/HomeBottomNav';
import {
    Link
} from "react-router-dom";

export default function Header(props) {
    console.log(props);
    return (
        <header className="header">
            <div className="header-top">
                <div className="container">
                    <div className="header-left">
                        <p className="welcome-msg">Welcome to Wolmart Store message or remove its!</p>
                    </div>
                    <div className="header-right">
                        <div className="dropdown">
                            <a href="#">USD</a>
                            <div className="dropdown-box">
                                <a href="#">USD</a>
                                <a href="#">INR</a>
                            </div>
                        </div>


                        <div className="dropdown">
                            <a href="#"><img src={window.location.origin + "/assets/images/flags/eng.png"} alt="ENG Flag" width="14"
                                    height="8" className="dropdown-image" /> ENG</a>
                            <div className="dropdown-box">
                                <a href="#">
                                    <img src={window.location.origin + "/assets/images/flags/eng.png"} alt="ENG Flag" width="14" height="8"
                                        className="dropdown-image" />
                                    ENG
                                </a>
                                <a href="#">
                                    <img src={window.location.origin + "/assets/images/flags/fra.png"} alt="FRA Flag" width="14" height="8"
                                        className="dropdown-image" />
                                    FRA
                                </a>
                            </div>
                        </div>
                        
                        <span className="divider d-lg-show"></span>
                        <a href="#" className="d-lg-show">Blog</a>
                        <Link className="d-lg-show" to="/contact-us">Contact Us</Link>
                        {/* <a href="#" className="d-lg-show">My Account</a> */}
                        <a href="#" className="d-lg-show login sign-in"><i className="w-icon-account"></i>Sign In</a>
                        <span className="delimiter d-lg-show">/</span>
                        <a href="#" className="ml-0 d-lg-show login register">Register</a>
                        
                    </div>
                </div>
            </div>


            <div className="header-middle">
                <div className="container">
                    <div className="header-left mr-md-4">
                        <a href="/" className="mobile-menu-toggle  w-icon-hamburger" /> 
                        <a href="/" className="logo ml-lg-0" >
                            <img src={window.location.origin + "/assets/images/logo.png"} alt="logo" width="144" height="45" />
                        </a>
                        <form method="get" action="search" className="header-search hs-expanded hs-round d-none d-md-flex input-wrapper">
                            <div className="select-box">
                                <select id="category" name="category">
                                    <option value="">All Categories</option>
                                    <option value="6172c969dd056c288cc42fed">Fashion</option>
                                    <option value="6172c958dd056c288cc42fe9">Furniture</option>
                                    <option value="6172c93ddd056c288cc42fe3">Shoes</option>
                                    <option value="6172c91fdd056c288cc42fdf">Sports</option>
                                    <option value="6172c94fdd056c288cc42fe7">Games</option>
                                    <option value="6172c962dd056c288cc42feb">Computers</option>
                                    <option value="6172c962dd056c288cc42feb">Electronics</option>
                                    <option value="6172c958dd056c288cc42fe9">Kitchen</option>
                                    <option value="6172c969dd056c288cc42fed">Clothing</option>
                                </select>
                            </div>
                            <input type="text" className="form-control" name="search" id="search"
                                placeholder="Search in..." required />
                            <button className="btn btn-search" type="submit"><i className="w-icon-search"></i>
                            </button>
                        </form>
                    </div>
                    <div className="header-right ml-4">
                        <div className="header-call d-xs-show d-lg-flex align-items-center">
                            <a href="tel:#" className="w-icon-call"> </a>
                            <div className="call-info d-lg-show">
                                <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                                    <a href="mailto:#" className="text-capitalize">Live Chat</a> or :</h4>
                                <a href="tel:#" className="phone-number font-weight-bolder ls-50">0(800)123-456</a>
                            </div>
                        </div>
                        <Link className="wishlist label-down link d-xs-show" to="/wishlist">
                            <i className="w-icon-heart"></i>
                            <span className="wishlist-label d-lg-show">Wishlist</span>
                        </Link>
                        <a className="compare label-down link d-xs-show" href="compare.html">
                            <i className="w-icon-compare"></i>
                            <span className="compare-label d-lg-show">Compare</span>
                        </a>
                        <div className="dropdown cart-dropdown cart-offcanvas mr-0 mr-lg-2">
                            <div className="cart-overlay"></div>
                            <Link to="/carts" className="cart-toggle label-down link">
                                <i className="w-icon-cart">
                                    <span className="cart-count">2</span>
                                </i>
                                <span className="cart-label">Cart</span>
                            </Link>
                            <div className="dropdown-box">
                                <div className="cart-header">
                                    <span>Shopping Cart</span>
                                    <Link to="/carts" className="btn-close">Close<i className="w-icon-long-arrow-right"></i></Link>
                                </div>

                                <div className="products">
                                    <div className="product product-cart">
                                        <div className="product-detail">
                                            <a href="product-default.html" className="product-name">Beige knitted
                                                elas<br/>tic
                                                runner shoes</a>
                                            <div className="price-box">
                                                <span className="product-quantity">1</span>
                                                <span className="product-price">$25.68</span>
                                            </div>
                                        </div>
                                        <figure className="product-media">
                                            <Link to="/productDetails">
                                                <img src="assets/images/cart/product-1.jpg" alt="product" height="84"
                                                    width="94" />
                                            </Link>
                                        </figure>
                                        <button className="btn btn-link btn-close">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>

                                    <div className="product product-cart">
                                        <div className="product-detail">
                                            <Link to="/productDetails" className="product-name">Blue utility
                                                pina<br />fore
                                                denim dress</Link>
                                            <div className="price-box">
                                                <span className="product-quantity">1</span>
                                                <span className="product-price">$32.99</span>
                                            </div>
                                        </div>
                                        <figure className="product-media">
                                            <Link to="/productDetails">
                                                <img src="assets/images/cart/product-2.jpg" alt="product" width="84"
                                                    height="94" />
                                            </Link>
                                        </figure>
                                        <button className="btn btn-link btn-close">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="cart-total">
                                    <label>Subtotal:</label>
                                    <span className="price">$58.67</span>
                                </div>

                                <div className="cart-action">
                                    <Link to="/carts" className="btn btn-dark btn-outline btn-rounded">View Cart</Link>
                                    <Link to="/checkout" className="btn btn-primary  btn-rounded">Checkout</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <HomeBottomNav isHome={props.isHome} />
        </header>
    )
}
