import React from 'react';
import { Link } from 'react-router-dom';
import CategoriesData from '../Jsons/Categories.json'

function Categories(props) {

    function CategoryComponent({ datas }) {
        return (
            <div className="category category-classNameic category-absolute overlay-zoom br-xs">
                <Link to={"/productCategories/" + datas._id } className="category-media">
                    <img src={datas.image} alt="Category" width="130"
                        height="130" />
                </Link>
                <div className="category-content">
                    <h4 className="category-name">{datas.name}</h4>
                    <Link to={"/productCategories/" + datas._id } className="btn btn-primary btn-link btn-underline">Shop
                        Now</Link>
                </div>
            </div>
        )
    }

    return (
        <section className="category-section top-category bg-grey pt-10 pb-10 appear-animate">
                <div className="container pb-2">
                    <h2 className="title justify-content-center pt-1 ls-normal mb-5">Top Categories Of The Month</h2>
                    <div className="owl-carousel owl-theme row cols-lg-6 cols-md-5 cols-sm-3 cols-2" data-owl-options="{
                        'nav': false,
                        'dots': false,
                        'margin': 20,
                        'responsive': {
                            '0': {
                                'items': 2
                            },
                            '576': {
                                'items': 3
                            },
                            '768': {
                                'items': 5
                            },
                            '992': {
                                'items': 6
                            }
                        }
                    }">

                        {CategoriesData.map((items, key) => {
                            return (
                                <CategoryComponent datas={items} key={key} />
                            )
                        })}

                    </div>
                </div>
            </section>
    );
}

export default Categories;