import React from 'react'

export default function RecentlyViewed() {

    const recentProducts = [
        {
            "image": "assets/images/demos/demo1/products/7-1.jpg",
            "name": "Women's Fashion Handbag",
        },
        {
            "image": "assets/images/demos/demo1/products/7-2.jpg",
            "name": "Electric Frying Pan",
        },
        {
            "image": "assets/images/demos/demo1/products/7-3.jpg",
            "name": "Black Winter Skating",
        },
        {
            "image": "assets/images/demos/demo1/products/7-4.jpg",
            "name": "HD Television",
        },
        {
            "image": "assets/images/demos/demo1/products/7-5.jpg",
            "name": "Home Sofa",
        },
        {
            "image": "assets/images/demos/demo1/products/7-6.jpg",
            "name": "USB Receipt",
        },
        {
            "image": "assets/images/demos/demo1/products/7-7.jpg",
            "name": "Electric Rice-Cooker",
        },
        {
            "image": "assets/images/demos/demo1/products/7-8.jpg",
            "name": "Table Lamp",
        }
    ]

    function ProductViewedComponen({ datalist }) {
        return (
            <div className="product-wrap mb-0">
                <div className="product text-center product-absolute">
                    <figure className="product-media">
                        <a href="#">
                            <img src={datalist.image} alt="Category Descriptions"
                                width="130" height="146" style={{ backgroundColor: "#fff" }} /> 
                        </a>
                    </figure>
                    <h4 className="product-name">
                        <a href="#">{datalist.name}</a>
                    </h4>
                </div>
            </div>
        )
    }
 
    return (
        <div>
            <h2 className="title title-underline mb-4 ls-normal appear-animate">Your Recent Views</h2>
            <div className="owl-carousel owl-theme owl-shadow-carousel appear-animate row cols-xl-8 cols-lg-6 cols-md-4 cols-2 pb-2 mb-10"
                data-owl-options="{
                'nav': false,
                'dots': true,
                'margin': 20,
                'responsive': {
                    '0': {
                        'items': 2
                    },
                    '576': {
                        'items': 3
                    },
                    '768': {
                        'items': 5
                    },
                    '992': {
                        'items': 6
                    },
                    '1200': {
                        'items': 8,
                        'dots': false
                    }
                }
            }">
                
                {recentProducts.map((item, key) => {
                    return (
                        <ProductViewedComponen datalist={item} key={key} />
                    )
                })}

            </div>    
        </div>
    )
}
