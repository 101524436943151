import React from 'react';
import ProductList from './ProductList';
import ClothingData from '../Jsons/ProductColumnsDataLists.json';
import { Link } from 'react-router-dom';

export default function ClothingApparels() {

    function ProductListing({ dataItem }) {
        return (
            <div className="product-col">
                {dataItem.products.map((item, key) => {
                    return (
                        <ProductList key={key} _id={item._id} name={item.name} amount={item.amount} rating={item.rating} reviews={item.reviews} image={item.image} />
                    )
                })}
            </div>
        )
    }

    return (
        <div className="product-wrapper-1 appear-animate mb-5">
            <div className="title-link-wrapper pb-1 mb-4">
                <h2 className="title ls-normal mb-0">Clothing & Apparel</h2>
                <Link to="/productCategories/6172c969dd056c288cc42fed" className="font-size-normal font-weight-bold ls-25 mb-0">More
                    Products<i className="w-icon-long-arrow-right"></i></Link>
            </div>
            <div className="row">
                <div className="col-lg-3 col-sm-4 mb-4">
                    <div className="banner h-100 br-sm" style={{ backgroundImage: `url('assets/images/demos/demo1/banners/2.jpg')`, 
                        backgroundColor: "#ebeced" }}>
                        <div className="banner-content content-top">
                            <h5 className="banner-subtitle font-weight-normal mb-2">Weekend Sale</h5>
                            <hr className="banner-divider bg-dark mb-2" />
                            <h3 className="banner-title font-weight-bolder ls-25 text-uppercase">
                                New Arrivals<br /> <span
                                    className="font-weight-normal text-capitalize">Collection</span>
                            </h3>
                            <Link to="/productCategories/6172c969dd056c288cc42fed" 
                                className="btn btn-dark btn-outline btn-rounded btn-sm">shop Now</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-sm-8">
                    <div className="owl-carousel owl-theme row cols-xl-4 cols-lg-3 cols-2" data-owl-options="{
                        'nav': false,
                        'dots': true,
                        'margin': 20,
                        'responsive': {
                            '0': {
                                'items': 2
                            },
                            '576': {
                                'items': 2
                            },
                            '992': {
                                'items': 3
                            },
                            '1200': {
                                'items': 4
                            }
                        }
                    }">

                        {ClothingData.map((datas, key) => {
                            return (
                                <ProductListing key={key} dataItem={datas} />
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
