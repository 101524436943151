import React from 'react'

export default function TabTwo() {
    return (
        <div>
            <ul className="list-none">
                <li>
                    <label>Model</label>
                    <p>Skysuite 320</p>
                </li>
                <li>
                    <label>Color</label>
                    <p>Black</p>
                </li>
                <li>
                    <label>Size</label>
                    <p>Large, Small</p>
                </li>
                <li>
                    <label>Guarantee Time</label>
                    <p>3 Months</p>
                </li>
            </ul>
        </div>
    )
}
