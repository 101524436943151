import React, { useEffect, useState }  from 'react';
import Header from '../../Header'

import shortid from "shortid";
import { BASE_URL } from '../../Constants';
import { Link } from 'react-router-dom';
export default function CartLists() {

    function getRandomKey() {
        return shortid.generate();
    }

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [CartLists1, setItems] = useState([]);
    const [wislistItems, setWislistItems] = useState([]);

    const CartLists = [
        {
            "image": "assets/images/shop/12.jpg",
            "name": "classic Simple Backpack",
            "price": "$40.00"
        },
        {
            "image": "assets/images/shop/13.jpg",
            "name": "Smart Watch",
            "price": "$60.00"
        }
    ]

    function incrementCount(_id) {

        let wishlisted = localStorage.getItem('cartItems');
        
        let parsedar = JSON.parse(wishlisted);

        let newarr = parsedar.map(el => (el._id == _id ? {...el,...{qty: el.qty + 1} } : el));

        localStorage.setItem('cartItems', JSON.stringify(newarr));
        
    }

    function decrementCount(_id) {
        let wishlisted = localStorage.getItem('cartItems');
        
        let parsedar = JSON.parse(wishlisted);

        let newarr = parsedar.map(el => (el._id == _id ? {...el,...{qty: el.qty - 1} } : el));

        localStorage.setItem('cartItems', JSON.stringify(newarr));
    }

    function removeList(_id) {
        let newarray = localStorage.getItem('cartItems');
        newarray = JSON.parse(newarray);

        let filteredarray = newarray.filter((element) => element._id !=  _id);
        console.log("filteredarray", filteredarray);
        setWislistItems(filteredarray);

        localStorage.setItem('cartItems', JSON.stringify(filteredarray));

    }

    useEffect(() => {

        setWislistItems(localStorage.getItem('cartItems'));

        let wishlisted = localStorage.getItem('cartItems');

        console.log("wishlisted", JSON.parse(wishlisted));

        fetch( BASE_URL + "/API/V1/getCartData", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "wishlistIds": JSON.parse(wishlisted),
                }
            )
        })
        .then(res => res.json())
        .then((result) => 
        {
                console.log("result", result);
                setItems(result);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, [wislistItems]);

    function ProductItems({ items }) {
        return (
            <tr>
                <td className="product-thumbnail">
                    <div className="p-relative">
                        <a href="product-default.html">
                            <figure>
                                <img src={items.data[0].images[0].images} alt="product" width="300" height="338" />
                            </figure>
                        </a>
                        <button type="button" onClick={() => removeList(items.data[0]._id) } className="btn btn-close"><i className="fas fa-times"></i></button>
                    </div>
                </td>
                <td className="product-name">
                    <a href="product-default.html">
                        {items.data[0].productName}
                    </a>
                </td>
                <td className="product-price"><span className="amount">{items.data[0].price}</span></td>
                <td className="product-quantity">
                    <div className="input-group">
                        <input onChange={() => {}} className="quantity form-control" value={items.qty} type="number" min="1" max="100000" />
                        <button onClick={() => incrementCount(items.data[0]._id)} className="quantity-plus w-icon-plus"></button>
                        <button onClick={() => decrementCount(items.data[0]._id)} className="quantity-minus w-icon-minus"></button>
                    </div>
                </td>
                <td className="product-subtotal">
                    <span className="amount">{items.data[0].price}</span>
                </td>
            </tr>
        )
    }
    return (
        <div>
            <Header isHome={false} />

            <main className="main cart">
                <nav className="breadcrumb-nav">
                    <div className="container">
                        <ul className="breadcrumb shop-breadcrumb bb-no">
                            <li className="active"><a href="cart.html">Shopping Cart</a></li>
                            <li><a href="checkout.html">Checkout</a></li>
                            <li><a href="order.html">Order Complete</a></li>
                        </ul>
                    </div>
                </nav>
                
                <div className="page-content">
                    <div className="container">
                        <div className="row gutter-lg mb-10">
                            <div className="col-lg-8 pr-lg-4 mb-6">
                                <table className="shop-table cart-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name"><span>Product</span></th>
                                            <th></th>
                                            <th className="product-price"><span>Price</span></th>
                                            <th className="product-quantity"><span>Quantity</span></th>
                                            <th className="product-subtotal"><span>Subtotal</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {CartLists1.map((data, key) => {
                                            return (
                                                <ProductItems items={data} key={key} />
                                            )
                                        })}
                                            
                                    </tbody>
                                </table>

                                <div className="cart-action mb-6">
                                    <a href="cart.html#" className="btn btn-dark btn-rounded btn-icon-left btn-shopping mr-auto"><i className="w-icon-long-arrow-left"></i>Continue Shopping</a>
                                    <button type="submit" className="btn btn-rounded btn-default btn-clear" name="clear_cart" value="Clear Cart">Clear Cart</button> 
                                    <button type="submit" className="btn btn-rounded btn-update disabled" name="update_cart" value="Update Cart">Update Cart</button>
                                </div>

                                <form className="coupon">
                                    <h5 className="title coupon-title font-weight-bold text-uppercase">Coupon Discount</h5>
                                    <input type="text" className="form-control mb-4" placeholder="Enter coupon code here..." required />
                                    <button className="btn btn-dark btn-outline btn-rounded">Apply Coupon</button>
                                </form>
                            </div>
                            <div className="col-lg-4 sticky-sidebar-wrapper">
                                <div className="sticky-sidebar">
                                    <div className="cart-summary mb-4">
                                        <h3 className="cart-title text-uppercase">Cart Totals</h3>
                                        <div className="cart-subtotal d-flex align-items-center justify-content-between">
                                            <label className="ls-25">Subtotal</label>
                                            <span>$100.00</span>
                                        </div>

                                        <hr className="divider" />

                                        <ul className="shipping-methods mb-2">
                                            <li>
                                                <label className="shipping-title text-dark font-weight-bold">Shipping</label>
                                            </li>
                                            <li>
                                                <div className="custom-radio">
                                                    <input type="radio" id="free-shipping" className="custom-control-input" name="shipping" />
                                                    <label htmlFor="free-shipping"
                                                        className="custom-control-label color-dark">Free
                                                        Shipping</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="custom-radio">
                                                    <input type="radio" id="local-pickup" className="custom-control-input" name="shipping" />
                                                    <label htmlFor="local-pickup"
                                                        className="custom-control-label color-dark">Local
                                                        Pickup</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="custom-radio">
                                                    <input type="radio" id="flat-rate" className="custom-control-input" name="shipping" />
                                                    <label htmlFor="flat-rate" className="custom-control-label color-dark">Flat
                                                        rate:
                                                        $5.00</label>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="shipping-calculator">
                                            <p className="shipping-destination lh-1">Shipping to <strong>CA</strong>.</p>

                                            <form className="shipping-calculator-form">
                                                <div className="form-group">
                                                    <div className="select-box">
                                                        <select name="country" className="form-control form-control-md">
                                                            <option value="default" >United States
                                                                (US)
                                                            </option>
                                                            <option value="us">United States</option>
                                                            <option value="uk">United Kingdom</option>
                                                            <option value="fr">France</option>
                                                            <option value="aus">Australia</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="select-box">
                                                        <select name="state" className="form-control form-control-md">
                                                            <option value="default" >California
                                                            </option>
                                                            <option value="ohaio">Ohaio</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control form-control-md" type="text" name="town-city" placeholder="Town / City" />
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control form-control-md" type="text" name="zipcode" placeholder="ZIP" />
                                                </div>
                                                <button type="submit" className="btn btn-dark btn-outline btn-rounded">Update
                                                    Totals</button>
                                            </form>
                                        </div>

                                        <hr className="divider mb-6" />
                                        <div className="order-total d-flex justify-content-between align-items-center">
                                            <label>Total</label>
                                            <span className="ls-50">$100.00</span>
                                        </div>
                                        <Link to="/checkout" className="btn btn-block btn-dark btn-icon-right btn-rounded  btn-checkout">Proceed to checkout<i className="w-icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    )
}
