import React from 'react'
import { Link } from 'react-router-dom'

export default function ProductComponents({ data }) {

    return (
        <div className="product">
            <figure className="product-media">
                <Link to={ "/productDetails/" + data._id } >
                    <img src={window.location.origin + "/" + data.images[0].images} alt="Product" width="300" height="338" />
                </Link>
                <div className="product-action-vertical">
                    <a href="#" className="btn-product-icon btn-cart w-icon-cart"
                        title="Add to cart"></a>
                    <a href="#" className="btn-product-icon btn-wishlist w-icon-heart"
                        title="Add to wishlist"></a>
                    <a href="#" className="btn-product-icon btn-compare w-icon-compare"
                        title="Add to Compare"></a>
                </div>
                <div className="product-action">
                <Link to={ "/productDetails/" + data._id } className="btn-product btn-quickview" title="Quick View">Quick
                        View
                </Link>
                </div>
            </figure>
            <div className="product-details">
                <div className="product-cat"><a href="#">{data.category}</a>
                </div>
                <h4 className="product-name"><Link to={ "/productDetails/" + data._id } >{data.productName}</Link>
                </h4>
                <div className="ratings-container">
                    <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }}></span>
                        <span className="tooltiptext tooltip-top"></span>
                    </div>
                    <a href="#" className="rating-reviews">(3 reviews)</a>
                </div>
                <div className="product-pa-wrapper">
                    <div className="product-price">
                    <ins className="new-price">{data.price}</ins>
                    {data.oldPrice && 
                        <del className="old-price">{data.oldPrice}</del>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}
