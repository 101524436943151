import React from 'react'

export default function TabFor() {
    return (
        <div>
            <div className="row mb-4">
                <div className="col-xl-4 col-lg-5 mb-4">
                    <div className="ratings-wrapper">
                        <div className="avg-rating-container">
                            <h4 className="avg-mark font-weight-bolder ls-50">3.3</h4>
                            <div className="avg-rating">
                                <p className="text-dark mb-1">Average Rating</p>
                                <div className="ratings-container">
                                    <div className="ratings-full">
                                        <span className="ratings" style={{ width: '60%' }}></span>
                                        <span className="tooltiptext tooltip-top"></span>
                                    </div>
                                    <a href="product-default.html#" className="rating-reviews">(3 Reviews)</a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="ratings-value d-flex align-items-center text-dark ls-25">
                            <span
                                className="text-dark font-weight-bold">66.7%</span>Recommended<span
                                className="count">(2 of 3)</span>
                        </div>
                        <div className="ratings-list">
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings" style={{ width: '100%' }}></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <div className="progress-bar progress-bar-sm ">
                                    <span></span>
                                </div>
                                <div className="progress-value">
                                    <mark>70%</mark>
                                </div>
                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings" style={{ width: '80%' }}></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <div className="progress-bar progress-bar-sm ">
                                    <span></span>
                                </div>
                                <div className="progress-value">
                                    <mark>30%</mark>
                                </div>
                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings" style={{ width: '60%' }}></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <div className="progress-bar progress-bar-sm ">
                                    <span></span>
                                </div>
                                <div className="progress-value">
                                    <mark>40%</mark>
                                </div>
                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings" style={{ width: '40%' }}></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <div className="progress-bar progress-bar-sm ">
                                    <span></span>
                                </div>
                                <div className="progress-value">
                                    <mark>0%</mark>
                                </div>
                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings" style={{ width: '20%' }}></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <div className="progress-bar progress-bar-sm ">
                                    <span></span>
                                </div>
                                <div className="progress-value">
                                    <mark>0%</mark>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-7 mb-4">
                    <div className="review-form-wrapper">
                        <h3 className="title tab-pane-title font-weight-bold mb-1">Submit Your
                            Review</h3>
                        <p className="mb-3">Your email address will not be published. Required
                            fields are marked *</p>
                        <form action="#" method="POST" className="review-form">
                            <div className="rating-form">
                                <label htmlFor="rating">Your Rating Of This Product :</label>
                                <span className="rating-stars">
                                    <a className="star-1" href="product-default.html#">1</a>
                                    <a className="star-2" href="product-default.html#">2</a>
                                    <a className="star-3" href="product-default.html#">3</a>
                                    <a className="star-4" href="product-default.html#">4</a>
                                    <a className="star-5" href="product-default.html#">5</a>
                                </span>
                                <select name="rating" id="rating" required=""
                                    style={{ display: 'none' }}>
                                    <option value="">Rate…</option>
                                    <option value="5">Perfect</option>
                                    <option value="4">Good</option>
                                    <option value="3">Average</option>
                                    <option value="2">Not that bad</option>
                                    <option value="1">Very poor</option>
                                </select>
                            </div>
                            <textarea cols="30" rows="6"
                                placeholder="Write Your Review Here..." className="form-control"
                                id="review"></textarea>
                            <div className="row gutter-md">
                                <div className="col-md-6">
                                    <input type="text" className="form-control"
                                        placeholder="Your Name" id="author" />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className="form-control"
                                        placeholder="Your Email" id="email_1" />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" className="custom-checkbox"
                                    id="save-checkbox" />
                                <label htmlFor="save-checkbox">Save my name, email, and website
                                    in this browser for the next time I comment.</label>
                            </div>
                            <button type="submit" className="btn btn-dark">Submit
                                Review</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="tab tab-nav-boxed tab-nav-outline tab-nav-center">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a href="product-default.html#show-all" className="nav-link active">Show All</a>
                    </li>
                    <li className="nav-item">
                        <a href="product-default.html#helpful-positive" className="nav-link">Most Helpful
                            Positive</a>
                    </li>
                    <li className="nav-item">
                        <a href="product-default.html#helpful-negative" className="nav-link">Most Helpful
                            Negative</a>
                    </li>
                    <li className="nav-item">
                        <a href="product-default.html#highest-rating" className="nav-link">Highest Rating</a>
                    </li>
                    <li className="nav-item">
                        <a href="product-default.html#lowest-rating" className="nav-link">Lowest Rating</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="show-all">
                        <ul className="comments list-style-none">
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/1-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:54 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '60%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>pellentesque habitant morbi tristique senectus
                                            et. In dictum non consectetur a erat.
                                            Nunc ultrices eros in cursus turpis massa
                                            tincidunt ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-1.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-1-800x900.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/2-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:52 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '80%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>Nullam a magna porttitor, dictum risus nec,
                                            faucibus sapien.
                                            Ultrices eros in cursus turpis massa tincidunt
                                            ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-2.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-2.jpg" />
                                                    </figure>
                                                </a>
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-3.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-3.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/3-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:21 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '60%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>In fermentum et sollicitudin ac orci phasellus. A
                                            condimentum vitae
                                            sapien pellentesque habitant morbi tristique
                                            senectus et. In dictum
                                            non consectetur a erat. Nunc scelerisque viverra
                                            mauris in aliquam sem fringilla.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (0)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (1)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane" id="helpful-positive">
                        <ul className="comments list-style-none">
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/1-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:54 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '60%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>pellentesque habitant morbi tristique senectus
                                            et. In dictum non consectetur a erat.
                                            Nunc ultrices eros in cursus turpis massa
                                            tincidunt ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-1.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-1.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/2-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:52 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '80%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>Nullam a magna porttitor, dictum risus nec,
                                            faucibus sapien.
                                            Ultrices eros in cursus turpis massa tincidunt
                                            ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-2.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-2-800x900.jpg" />
                                                    </figure>
                                                </a>
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-3.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-3-800x900.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane" id="helpful-negative">
                        <ul className="comments list-style-none">
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/3-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:21 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '60%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>In fermentum et sollicitudin ac orci phasellus. A
                                            condimentum vitae
                                            sapien pellentesque habitant morbi tristique
                                            senectus et. In dictum
                                            non consectetur a erat. Nunc scelerisque viverra
                                            mauris in aliquam sem fringilla.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (0)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (1)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane" id="highest-rating">
                        <ul className="comments list-style-none">
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/2-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:52 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '80%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>Nullam a magna porttitor, dictum risus nec,
                                            faucibus sapien.
                                            Ultrices eros in cursus turpis massa tincidunt
                                            ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-2.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-2-800x900.jpg" />
                                                    </figure>
                                                </a>
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-3.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-3-800x900.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane" id="lowest-rating">
                        <ul className="comments list-style-none">
                            <li className="comment">
                                <div className="comment-body">
                                    <figure className="comment-avatar">
                                        <img src="assets/images/agents/1-100x100.png"
                                            alt="Commenter Avatar" width="90" height="90" />
                                    </figure>
                                    <div className="comment-content">
                                        <h4 className="comment-author">
                                            <a href="product-default.html#">John Doe</a>
                                            <span className="comment-date">March 22, 2021 at
                                                1:54 pm</span>
                                        </h4>
                                        <div className="ratings-container comment-rating">
                                            <div className="ratings-full">
                                                <span className="ratings"
                                                    style={{ width: '60%' }}></span>
                                                <span
                                                    className="tooltiptext tooltip-top"></span>
                                            </div>
                                        </div>
                                        <p>pellentesque habitant morbi tristique senectus
                                            et. In dictum non consectetur a erat.
                                            Nunc ultrices eros in cursus turpis massa
                                            tincidunt ante in nibh mauris cursus mattis.
                                            Cras ornare arcu dui vivamus arcu felis bibendum
                                            ut tristique.</p>
                                        <div className="comment-action">
                                            <a href="product-default.html#"
                                                className="btn btn-secondary btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-up"></i>Helpful (1)
                                            </a>
                                            <a href="product-default.html#"
                                                className="btn btn-dark btn-link btn-underline sm btn-icon-left font-weight-normal text-capitalize">
                                                <i className="far fa-thumbs-down"></i>Unhelpful
                                                (0)
                                            </a>
                                            <div className="review-image">
                                                <a href="product-default.html#">
                                                    <figure>
                                                        <img src="assets/images/products/default/review-img-3.jpg"
                                                            width="60" height="60"
                                                            alt="Attachment image of John Doe's review on Electronics Black Wrist Watch"
                                                            data-zoom-image="assets/images/products/default/review-img-3-800x900.jpg" />
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
