/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default function HomeBottomNav({ isHome }) {
    return (
        <div className="header-bottom sticky-content fix-top sticky-header">
            <div className="container">
                <div className="inner-wrap">
                    <div className="header-left">
                        {isHome === true &&
                        <div className="dropdown category-dropdown has-border" data-visible="true">
                            <a href="#" className="category-toggle text-dark" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="true" data-display="static"
                                title="Browse Categories">
                                <i className="w-icon-category"></i>
                                <span>Browse Categories</span>
                            </a>

                            <div className="dropdown-box">
                                <ul className="menu vertical-menu category-menu">
                                    <li>
                                        <Link to="/productCategories/6176d9db34690dbb9a7b56e4" >
                                            <i className="w-icon-tshirt2"></i>Fashion
                                        </Link>
                                        <ul className="megamenu">
                                            <li>
                                                <h4 className="menu-title">Women</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176da0034690dbb9a7b56e6" >New Arrivals</Link> </li>
                                                    <li><Link to="/productCategories/6176da0f34690dbb9a7b56e8" >Best Sellers</Link></li>
                                                    <li><Link to="/productCategories/6176da2c34690dbb9a7b56ea" >Trending</Link></li>
                                                    <li><Link to="/productCategories/6172c969dd056c288cc42fed" >Clothing</Link></li>
                                                    <li><Link to="/productCategories/6172c93ddd056c288cc42fe3" >Shoes</Link></li>
                                                    <li><Link to="/productCategories/6176db0b9a5b0c9775307328" >Bags</Link></li>
                                                    <li><Link to="/productCategories/6176db429a5b0c977530732a" >Accessories</Link></li>
                                                    <li><Link to="/productCategories/6176db669a5b0c977530732c" >Jewlery & Watches</Link></li>
                                                    <li><Link to="/productCategories/6172c969dd056c288cc42fed" >Sale</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 className="menu-title">Men</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176da0034690dbb9a7b56e6" >New Arrivals</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176da0f34690dbb9a7b56e8" >Best Sellers</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176da2c34690dbb9a7b56ea" >Trending</Link></li>
                                                    <li><Link to="/productCategories/6172c969dd056c288cc42fed" >Clothing</Link></li>
                                                    <li><Link to="/productCategories/6172c93ddd056c288cc42fe3" >Shoes</Link></li>
                                                    <li><Link to="/productCategories/6176db0b9a5b0c9775307328" >Bags</Link></li>
                                                    <li><Link to="/productCategories/6176db429a5b0c977530732a" >Accessories</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176db669a5b0c977530732c" >Jewlery &
                                                            Watches</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="banner-fixed menu-banner menu-banner2">
                                                    <figure>
                                                        <img src="assets/images/menu/banner-2.jpg" alt="Menu Banner"
                                                            width="235" height="347" />
                                                    </figure>
                                                    <div className="banner-content">
                                                        <div className="banner-price-info mb-1 ls-normal">Get up to
                                                            <strong
                                                                className="text-primary text-uppercase">20%Off</strong>
                                                        </div>
                                                        <h3 className="banner-title ls-normal">Hot Sales</h3>
                                                        <Link to="/productCategories/6172c969dd056c288cc42fed" 
                                                            className="btn btn-dark btn-sm btn-link btn-slide-right btn-icon-right">
                                                            Shop Now<i className="w-icon-long-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176dcbe9a5b0c977530732e" >
                                            <i className="w-icon-home"></i>Home & Garden
                                        </Link>
                                        <ul className="megamenu">
                                            <li>
                                                <h4 className="menu-title">Bedroom</h4> 
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176dd859a5b0c9775307330" >Beds, Frames &
                                                            Bases</Link></li>
                                                    <li><Link to="/productCategories/6176ddb29a5b0c9775307332" >Dressers</Link></li>
                                                    <li><Link to="/productCategories/6176ddbd9a5b0c9775307334" >Nightstands</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176de0c9a5b0c9775307336" >Kid's Beds &
                                                            Headboards</Link></li>
                                                    <li><Link to="/productCategories/6176de3a9a5b0c9775307338" >Armoires</Link></li>
                                                </ul>

                                                <h4 className="menu-title mt-1">Living Room</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176de6e9a5b0c977530733a" >Coffee Tables</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176deb29a5b0c977530733c" >Chairs</Link></li>
                                                    <li><Link to="/productCategories/6176dee99a5b0c977530733e" >Tables</Link></li>
                                                    <li><Link to="/productCategories/6176df1f9a5b0c9775307340" >Futons & Sofa
                                                            Beds</Link></li>
                                                    <li><Link to="/productCategories/6176df869a5b0c9775307342" >Cabinets &
                                                            Chests</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 className="menu-title">Office</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176dfcc9a5b0c9775307344" >Office Chairs</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e0109a5b0c9775307346" >Desks</Link></li>
                                                    <li><Link to="/productCategories/6176e07c9a5b0c9775307348" >Bookcases</Link></li>
                                                    <li><Link to="/productCategories/6176e0a29a5b0c977530734a" >File Cabinets</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e0b89a5b0c977530734c" >Breakroom
                                                            Tables</Link></li>
                                                </ul>

                                                <h4 className="menu-title mt-1">Kitchen & Dining</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176e0f29a5b0c977530734e" >Dining Sets</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e1149a5b0c9775307350" >Kitchen Storage
                                                            Cabinets</Link></li>
                                                    <li><Link to="/productCategories/6176e1399a5b0c9775307352" >Bashers Racks</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e14a9a5b0c9775307354" >Dining Chairs</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e1669a5b0c9775307356" >Dining Room
                                                            Tables</Link></li>
                                                    <li><Link to="/productCategories/6176e1b39a5b0c9775307358" >Bar Stools</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="menu-banner banner-fixed menu-banner3">
                                                    <figure>
                                                        <img src="assets/images/menu/banner-3.jpg" alt="Menu Banner"
                                                            width="235" height="461" />
                                                    </figure>
                                                    <div className="banner-content">
                                                        <h4
                                                            className="banner-subtitle font-weight-normal text-white mb-1">
                                                            Restroom</h4>
                                                        <h3
                                                            className="banner-title font-weight-bolder text-white ls-normal">
                                                            Furniture Sale</h3>
                                                        <div
                                                            className="banner-price-info text-white font-weight-normal ls-25">
                                                            Up to <span
                                                                className="text-secondary text-uppercase font-weight-bold">25%
                                                                Off</span></div>
                                                        <Link to="/productCategories/6172c958dd056c288cc42fe9" 
                                                            className="btn btn-white btn-link btn-sm btn-slide-right btn-icon-right">
                                                            Shop Now<i className="w-icon-long-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176e1f09a5b0c977530735a" >
                                            <i className="w-icon-electronics"></i>Electronics
                                        </Link>
                                        <ul className="megamenu">
                                            <li>
                                                <h4 className="menu-title">Laptops &amp; Computers</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176e2339a5b0c977530735c" >Desktop
                                                            Computers</Link></li>
                                                    <li><Link to="/productCategories/6176e27c9a5b0c977530735e" >Monitors</Link></li>
                                                    <li><Link to="/productCategories/6176e2a59a5b0c9775307360" >Laptops</Link></li>
                                                    <li><Link to="/productCategories/6176e6619a5b0c9775307362" >Hard Drives &amp;
                                                            Storage</Link></li>
                                                    <li><Link to="/productCategories/6176e69d9a5b0c9775307364" >Computer
                                                            Accessories</Link></li>
                                                </ul>

                                                <h4 className="menu-title mt-1">TV &amp; Video</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176e6fa9a5b0c9775307366" >TVs</Link></li>
                                                    <li><Link to="/productCategories/6176e7449a5b0c9775307368" >Home Audio
                                                            Speakers</Link></li>
                                                    <li><Link to="/productCategories/6176e7649a5b0c977530736a" >Projectors</Link></li>
                                                    <li><Link to="/productCategories/6176e7649a5b0c977530736a" >Media Streaming
                                                            Devices</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 className="menu-title">Digital Cameras</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6172c946dd056c288cc42fe5" >Digital SLR
                                                            Cameras</Link></li>
                                                    <li><Link to="/productCategories/6172c946dd056c288cc42fe5" >Sports & Action
                                                            Cameras</Link></li>
                                                    <li><Link to="/productCategories/6176e7b49a5b0c977530736c" >Camera Lenses</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e7f89a5b0c977530736e" >Photo Printer</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e82b9a5b0c9775307370" >Digital Memory
                                                            Cards</Link></li>
                                                </ul>

                                                <h4 className="menu-title mt-1">Cell Phones</h4>
                                                <hr className="divider"/>
                                                <ul>
                                                    <li><Link to="/productCategories/6176e8779a5b0c9775307372" >Mobile Phones</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e8be9a5b0c9775307374" >Premium Phones</Link>
                                                    </li>
                                                    <li><Link to="/productCategories/6176e8779a5b0c9775307372" >Qwerty Phones</Link></li>
                                                    <li><Link to="/productCategories/6176e91f9a5b0c9775307378" >Cellphone
                                                            Chargers</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="menu-banner banner-fixed menu-banner4">
                                                    <figure>
                                                        <img src="assets/images/menu/banner-4.jpg" alt="Menu Banner"
                                                            width="235" height="433" />
                                                    </figure>
                                                    <div className="banner-content">
                                                        <h4 className="banner-subtitle font-weight-normal">Deals Of The
                                                            Week</h4>
                                                        <h3 className="banner-title text-white">Save On Smart EarPhone
                                                        </h3>
                                                        <div
                                                            className="banner-price-info text-secondary font-weight-bolder text-uppercase text-secondary">
                                                            20% Off</div>
                                                        <Link to="/productCategories/6172c946dd056c288cc42fe5"
                                                            className="btn btn-white btn-outline btn-sm btn-rounded">Shop
                                                            Now</Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176ea1b9a5b0c9775307382" >
                                            <i className="w-icon-furniture"></i>Furniture
                                        </Link>
                                        <ul className="megamenu type2">
                                            <li className="row">
                                                <div className="col-md-3 col-lg-3 col-6">
                                                    <h4 className="menu-title">Furniture</h4>
                                                    <hr className="divider" />
                                                    <ul>
                                                        <li><Link to="/productCategories/6176df1f9a5b0c9775307340" >Sofas & Couches</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176deb29a5b0c977530733c" >Armchairs</Link></li>
                                                        <li><Link to="/productCategories/6176dd859a5b0c9775307330" >Bed Frames</Link></li>
                                                        <li><Link to="/productCategories/6176dee99a5b0c977530733e" >Beside Tables</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176dee99a5b0c977530733e" >Dressing Tables</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-6">
                                                    <h4 className="menu-title">Lighting</h4>
                                                    <hr className="divider" />
                                                    <ul>
                                                        <li><Link to="/productCategories/6176ea9e9a5b0c9775307384" >Light Bulbs</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176eae39a5b0c9775307386" >Lamps</Link></li>
                                                        <li><Link to="/productCategories/6176eb079a5b0c9775307388" >Celling Lights</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/Wall Lights" >Wall Lights</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176eb3b9a5b0c977530738a" >Bathroom
                                                                Lighting</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-6">
                                                    <h4 className="menu-title">Home Accessories</h4>
                                                    <hr className="divider" />
                                                    <ul>
                                                        <li><Link to="/productCategories/6176eb7f9a5b0c977530738c" >Decorative
                                                                Accessories</Link></li>
                                                        <li><Link to="/productCategories/6176ebac9a5b0c977530738e" >Candals &
                                                                Holders</Link></li>
                                                        <li><Link to="/productCategories/6176ebd29a5b0c9775307390" >Home Fragrance</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176ec089a5b0c9775307392" >Mirrors</Link></li>
                                                        <li><Link to="/productCategories/6176ec2f9a5b0c9775307394" >Clocks</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-6">
                                                    <h4 className="menu-title">Garden & Outdoors</h4>
                                                    <hr className="divider" />
                                                    <ul>
                                                        <li><Link to="/productCategories/6176ec8d9a5b0c9775307396" >Garden
                                                                Furniture</Link></li>
                                                        <li><Link to="/productCategories/6176ecb39a5b0c9775307398" >Lawn Mowers</Link>
                                                        </li>
                                                        <li><Link to="/productCategories/6176393cc85124b016dd4eb9" >Pressure
                                                                Washers</Link></li>
                                                        <li><Link to="/productCategories/6176393cc85124b016dd4eb9" >All Garden
                                                                Tools</Link></li>
                                                        <li><Link to="/productCategories/6176393cc85124b016dd4eb9" >Outdoor Dining</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="row">
                                                <div className="col-6">
                                                    <div className="banner banner-fixed menu-banner5 br-xs">
                                                        <figure>
                                                            <img src="assets/images/menu/banner-5.jpg" alt="Banner"
                                                                width="410" height="123"
                                                                style={{ backgroundColor: "#D2D2D2" }} />
                                                        </figure>
                                                        <div className="banner-content text-right y-50">
                                                            <h4
                                                                className="banner-subtitle font-weight-normal text-default text-capitalize">
                                                                New Arrivals</h4>
                                                            <h3
                                                                className="banner-title font-weight-bolder text-capitalize ls-normal">
                                                                Amazing Sofa</h3>
                                                            <div
                                                                className="banner-price-info font-weight-normal ls-normal">
                                                                Starting at <strong>$125.00</strong></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="banner banner-fixed menu-banner5 br-xs">
                                                        <figure>
                                                            <img src="assets/images/menu/banner-6.jpg" alt="Banner"
                                                                width="410" height="123"
                                                                style={{ backgroundColor: "#9F9888" }} />
                                                        </figure>
                                                        <div className="banner-content y-50">
                                                            <h4
                                                                className="banner-subtitle font-weight-normal text-white text-capitalize">
                                                                Best Seller</h4>
                                                            <h3
                                                                className="banner-title font-weight-bolder text-capitalize text-white ls-normal">
                                                                Chair &amp; Lamp</h3>
                                                            <div
                                                                className="banner-price-info font-weight-normal ls-normal text-white">
                                                                From <strong>$165.00</strong></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176e9489a5b0c977530737a" >
                                            <i className="w-icon-heartbeat"></i>Healthy & Beauty
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176e95c9a5b0c977530737c" >
                                            <i className="w-icon-gift"></i>Gift Ideas
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6172c94fdd056c288cc42fe7" >
                                            <i className="w-icon-gamepad"></i>Toy & Games
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176e9889a5b0c977530737e" >
                                            <i className="w-icon-ice-cream"></i>Cooking
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories//6176e8779a5b0c9775307372" >
                                            <i className="w-icon-ios"></i>Smart Phones
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6172c946dd056c288cc42fe5" >
                                            <i className="w-icon-camera"></i>Cameras & Photo
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productCategories/6176e9ed9a5b0c9775307380" >
                                            <i className="w-icon-ruby"></i>Accessories
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="#"
                                            className="font-weight-bold text-primary text-uppercase ls-25">
                                            View All Categories<i className="w-icon-angle-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        }
                        <nav className="main-nav">
                            <ul className="menu active-underline">
                                <li className="active">
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/productCategories">Shop</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <a href="#" className="d-xl-show"><i className="w-icon-map-marker mr-1"></i>Track Order</a>
                        <a href="#"><i className="w-icon-sale"></i>Daily Deals</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
