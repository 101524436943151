import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../Constants';
import ProductComponents from './Components/ProductComponents'

export default function RelatedProducts() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const [ProductsFromThisVendor, setProductData] = useState([]);

    useEffect(() => {
        fetch( BASE_URL + "/API/V1/getMoreProducts2", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => 
        {
                // console.log("result", result[0].data[0].options);
                setItems(result);
                setProductData(result);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, []);

    return (
        <section className="vendor-product-section">
            <div className="title-link-wrapper mb-4">
                <h4 className="title text-left">More Products From This Vendor</h4>
                <a href="product-default.html#" className="btn btn-dark btn-link btn-slide-right btn-icon-right">More
                    Products<i className="w-icon-long-arrow-right"></i></a>
            </div>
            <div className="owl-carousel owl-theme row cols-lg-3 cols-md-4 cols-sm-3 cols-2"
                data-owl-options="{
                'nav': false,
                'dots': false,
                'margin': 20,
                'responsive': {
                    '0': {
                        'items': 2
                    },
                    '576': {
                        'items': 3
                    },
                    '768': {
                        'items': 4
                    },
                    '992': {
                        'items': 3
                    }
                }
            }">
                
                {ProductsFromThisVendor.map((productData, key) => {
                    return (
                        <ProductComponents data={productData} key={key} />
                    )
                })}

            </div>
        </section>
    )
}
