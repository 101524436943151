import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Blogs from './Components/Blogs'
import Categories from './Components/Categories'
import Clients from './Components/Clients'
import ClothingApparels from './Components/ClothingApparels'
import ConsumerElectric from './Components/ConsumerElectric'
import HomeBestSellers from './Components/HomeBestSellers'
import HomeBigProductSlider from './Components/HomeBigProductSlider'
import Homeelements from './Components/Homeelements'
import HomenGarden from './Components/HomenGarden'
import HomeOfferWidget from './Components/HomeOfferWidget'
import HomePageBanner from './Components/HomePageBanner'
import PopularDepartmentProducts from './Components/PopularDepartmentProducts'
import RecentlyViewed from './Components/RecentlyViewed';

import shortid from "shortid";

export default function Main() {

    function getRandomKey() {
        return shortid.generate();
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // window.location.reload(); 
        window.history.scrollRestoration = 'manual';

        return () => {
            
        }
    }, [])

    return (
        <main className="main">
            
            <HomePageBanner key={getRandomKey()} />

            <div className="container">
                <Homeelements key={getRandomKey()} />

                <HomeOfferWidget key={getRandomKey()} />

                

                <div className="row deals-wrapper appear-animate mb-8">
                    
                    <HomeBigProductSlider key={getRandomKey()} />

                    <HomeBestSellers key={getRandomKey()} />
                </div>
            </div>

            <Categories key={getRandomKey()} />

            <div className="container">
                <h2 className="title justify-content-center ls-normal mb-4 mt-10 pt-1 appear-animate">Popular Departments
                </h2>

                <PopularDepartmentProducts key={getRandomKey()} />

                <div className="row category-cosmetic-lifestyle appear-animate mb-5">
                    <div className="col-md-6 mb-4">
                        <div className="banner banner-fixed category-banner-1 br-xs">
                            <figure>
                                <img src="assets/images/demos/demo1/categories/3-1.jpg" alt="Category Banner"
                                    width="610" height="200" style={{ backgroundColor: "#3B4B48" }} />
                            </figure>
                            <div className="banner-content y-50 pt-1">
                                <h5 className="banner-subtitle font-weight-bold text-uppercase">Natural Process</h5>
                                <h3 className="banner-title font-weight-bolder text-capitalize text-white">Cosmetic
                                    Makeup<br/>Professional</h3>
                                <Link to="/productCategories/6172c93ddd056c288cc42fe3"
                                    className="btn btn-white btn-link btn-underline btn-icon-right">Shop Now<i
                                        className="w-icon-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="banner banner-fixed category-banner-2 br-xs">
                            <figure>
                                <img src="assets/images/demos/demo1/categories/3-2.jpg" alt="Category Banner"
                                    width="610" height="200" style={{ backgroundColor: "#E5E5E5" }} />
                            </figure>
                            <div className="banner-content y-50 pt-1">
                                <h5 className="banner-subtitle font-weight-bold text-uppercase">Trending Now</h5>
                                <h3 className="banner-title font-weight-bolder text-capitalize">Women's
                                    Lifestyle<br />Collection</h3>
                                <Link to="/productCategories/6172c969dd056c288cc42fed"
                                    className="btn btn-dark btn-link btn-underline btn-icon-right">Shop Now<i
                                        className="w-icon-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>


                <ClothingApparels key={getRandomKey()} />

                <ConsumerElectric key={getRandomKey()} />

                <div className="banner banner-fashion appear-animate br-sm mb-9" style={{ backgroundImage: `url('assets/images/demos/demo1/banners/4.jpg')`,
                    backgroundColor: '#383839' }} >
                    <div className="banner-content align-items-center">
                        <div className="content-left d-flex align-items-center mb-3">
                            <div className="banner-price-info font-weight-bolder text-secondary text-uppercase lh-1 ls-25">
                                25
                                <sup className="font-weight-bold">%</sup><sub className="font-weight-bold ls-25">Off</sub>
                            </div>
                            <hr className="banner-divider bg-white mt-0 mb-0 mr-8" />
                        </div>
                        <div className="content-right d-flex align-items-center flex-1 flex-wrap">
                            <div className="banner-info mb-0 mr-auto pr-4 mb-3">
                                <h3 className="banner-title text-white font-weight-bolder text-uppercase ls-25">For Today's
                                    Fashion</h3>
                                <p className="text-white mb-0">Use code
                                    <span
                                        className="text-dark bg-white font-weight-bold ls-50 pl-1 pr-1 d-inline-block">Black
                                        <strong>12345</strong></span> to get best offer.</p>
                            </div>
                            <Link to="/productCategories/6172c969dd056c288cc42fed" 
                                className="btn btn-white btn-outline btn-rounded btn-icon-right mb-3">Shop Now<i
                                    className="w-icon-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <HomenGarden key={getRandomKey()} />

                <Clients key={getRandomKey()} />

                <Blogs key={getRandomKey()} />

                <RecentlyViewed key={getRandomKey()} />
                
            </div>
        </main>
    )
}
