import React from 'react'

export default function Content() {
    return (
        <div className="widget widget-icon-box mb-6">
            <div className="icon-box icon-box-side">
                <span className="icon-box-icon text-dark">
                    <i className="w-icon-truck"></i>
                </span>
                <div className="icon-box-content">
                    <h4 className="icon-box-title">Free Shipping & Returns</h4>
                    <p>For all orders over $99</p>
                </div>
            </div>
            <div className="icon-box icon-box-side">
                <span className="icon-box-icon text-dark">
                    <i className="w-icon-bag"></i>
                </span>
                <div className="icon-box-content">
                    <h4 className="icon-box-title">Secure Payment</h4>
                    <p>We ensure secure payment</p>
                </div>
            </div>
            <div className="icon-box icon-box-side">
                <span className="icon-box-icon text-dark">
                    <i className="w-icon-money"></i>
                </span>
                <div className="icon-box-content">
                    <h4 className="icon-box-title">Money Back Guarantee</h4>
                    <p>Any back within 30 days</p>
                </div>
            </div>
        </div>
    )
}
