import React from 'react';
import ProductList from './ProductList';

function PopularDepartmentProducts(props) {

    const ProductsData = [
        {
            "_id": "6172db1056843144aebe8063",
            "name": "Classic Hat",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-1-1.jpg"
        },
        {
            "_id": "6172daf256843144aebe805d",
            "name": "Women’s White Handbag",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        },
        {
            "_id": "6172db2956843144aebe8069",
            "name": "Multi Funtional Apple iPhone",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-3.jpg"
        },
        {
            "_id": "6172db3e56843144aebe806f",
            "name": "Fashion Blue Towel",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-4-1.jpg"
        },
        {
            "_id": "6172db4d56843144aebe8075",
            "name": "Apple Super Notecom",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-5.jpg"
        },
        {
            "_id": "6172db6056843144aebe807b",
            "name": "Women’s Comforter",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-6-1.jpg"
        },
        {
            "_id": "6172db7256843144aebe8081",
            "name": "Multi-colorful Music",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-7.jpg"
        },
        {
            "_id": "6172db8256843144aebe8087",
            "name": "Comfortable Backpack",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-8-1.jpg"
        },
        {
            "_id": "6172db9456843144aebe808d",
            "name": "Data Transformer Tool",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-9.jpg"
        },
        {
            "_id": "6172dba256843144aebe8093",
            "name": "Women’s hairdye",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        }
    ];

    const ProductsData2 = [
        
        {
            "_id": "6172db2956843144aebe8069",
            "name": "Multi Funtional Apple iPhone",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-3.jpg"
        },
        {
            "_id": "6172db3e56843144aebe806f",
            "name": "Fashion Blue Towel",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-4-1.jpg"
        },
        {
            "_id": "6172db1056843144aebe8063",
            "name": "Classic Hat",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-1-1.jpg"
        },
        {
            "_id": "6172db6056843144aebe807b",
            "name": "Women’s Comforter",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-6-1.jpg"
        },
        {
            "_id": "6172db7256843144aebe8081",
            "name": "Multi-colorful Music",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-7.jpg"
        },
        {
            "_id": "6172daf256843144aebe805d",
            "name": "Women’s White Handbag",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        },
        {
            "_id": "6172db4d56843144aebe8075",
            "name": "Apple Super Notecom",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-5.jpg"
        },
        {
            "_id": "6172db8256843144aebe8087",
            "name": "Comfortable Backpack",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-8-1.jpg"
        },
        {
            "_id": "6172db9456843144aebe808d",
            "name": "Data Transformer Tool",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-9.jpg"
        },
        {
            "_id": "6172dba256843144aebe8093",
            "name": "Women’s hairdye",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        }
    ];

    const ProductsData3 = [
        {
            "_id": "6172db1056843144aebe8063",
            "name": "Classic Hat",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-1-1.jpg"
        },
        {
            "_id": "6172db3e56843144aebe806f",
            "name": "Fashion Blue Towel",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-4-1.jpg"
        },
        {
            "_id": "6172db4d56843144aebe8075",
            "name": "Apple Super Notecom",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-5.jpg"
        },
        {
            "_id": "6172daf256843144aebe805d",
            "name": "Women’s White Handbag",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        },
        {
            "_id": "6172db2956843144aebe8069",
            "name": "Multi Funtional Apple iPhone",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-3.jpg"
        },
        {
            "_id": "6172db6056843144aebe807b",
            "name": "Women’s Comforter",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-6-1.jpg"
        },
        {
            "_id": "6172db8256843144aebe8087",
            "name": "Comfortable Backpack",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-8-1.jpg"
        },
        {
            "_id": "6172db7256843144aebe8081",
            "name": "Multi-colorful Music",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-7.jpg"
        },
        {
            "_id": "6172db9456843144aebe808d",
            "name": "Data Transformer Tool",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-9.jpg"
        },
        {
            "_id": "6172dba256843144aebe8093",
            "name": "Women’s hairdye",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        }
    ];

    const ProductsData4 = [
        
        {
            "_id": "6172db8256843144aebe8087",
            "name": "Comfortable Backpack",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-8-1.jpg"
        },
        {
            "_id": "6172daf256843144aebe805d",
            "name": "Women’s White Handbag",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        },
        {
            "_id": "6172db1056843144aebe8063",
            "name": "Classic Hat",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-1-1.jpg"
        },
        {
            "_id": "6172db3e56843144aebe806f",
            "name": "Fashion Blue Towel",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-4-1.jpg"
        },
        {
            "_id": "6172db4d56843144aebe8075",
            "name": "Apple Super Notecom",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-5.jpg"
        },
        {
            "_id": "6172db6056843144aebe807b",
            "name": "Women’s Comforter",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-6-1.jpg"
        },
        {
            "_id": "6172db7256843144aebe8081",
            "name": "Multi-colorful Music",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-7.jpg"
        },
        {
            "_id": "6172db2956843144aebe8069",
            "name": "Multi Funtional Apple iPhone",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-3.jpg"
        },
        {
            "_id": "6172db9456843144aebe808d",
            "name": "Data Transformer Tool",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-9.jpg"
        },
        {
            "_id": "6172dba256843144aebe8093",
            "name": "Women’s hairdye",
            "amount": 53,
            "rating": 3,
            "reviews": 4,
            "image": "assets/images/demos/demo1/products/3-2.jpg"
        }
    ];

    return (
        <>
        <div className="tab tab-nav-boxed tab-nav-outline appear-animate">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
                <li className="nav-item mr-2 mb-2">
                    <a className="nav-link active br-sm font-size-md ls-normal" href="#tab1-1">New arrivals</a>
                </li>
                <li className="nav-item mr-2 mb-2">
                    <a className="nav-link br-sm font-size-md ls-normal" href="#tab1-2">Best seller</a>
                </li>
                <li className="nav-item mr-2 mb-2">
                    <a className="nav-link br-sm font-size-md ls-normal" href="#tab1-3">most popular</a>
                </li>
                <li className="nav-item mr-0 mb-2">
                    <a className="nav-link br-sm font-size-md ls-normal" href="#tab1-4">Featured</a>
                </li>
            </ul>
        </div>

        <div className="tab-content product-wrapper appear-animate">
            <div className="tab-pane active pt-4" id="tab1-1">
                <div className="row cols-xl-5 cols-md-4 cols-sm-3 cols-2">
                    {ProductsData.map((data, key) => {
                        return (
                            <ProductList key={key} _id={data._id} name={data.name} amount={data.amount} rating={data.rating} reviews={data.reviews} image={data.image} />
                        )
                    })}
                    
                </div>
            </div>

            <div className="tab-pane pt-4" id="tab1-2">
                <div className="row cols-xl-5 cols-md-4 cols-sm-3 cols-2">
                    {ProductsData2.map((data, key) => {
                        return (
                            <ProductList key={key} name={data.name} amount={data.amount} rating={data.rating} reviews={data.reviews} image={data.image} />
                        )
                    })}
                </div>
            </div>

            <div className="tab-pane pt-4" id="tab1-3">
                <div className="row cols-xl-5 cols-md-4 cols-sm-3 cols-2">
                    {ProductsData3.map((data, key) => {
                        return (
                            <ProductList key={key} name={data.name} amount={data.amount} rating={data.rating} reviews={data.reviews} image={data.image} />
                        )
                    })}
                </div>
            </div>
            <div className="tab-pane pt-4" id="tab1-4">
                <div className="row cols-xl-5 cols-md-4 cols-sm-3 cols-2">
                    {ProductsData4.map((data, key) => {
                        return (
                            <ProductList key={key} name={data.name} amount={data.amount} rating={data.rating} reviews={data.reviews} image={data.image} />
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    );
}

export default PopularDepartmentProducts;