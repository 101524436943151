import React from 'react'

export default function VendorInfo() {
    return (
        <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
                <a href="#product-tab-description" className="nav-link active">Description</a>
            </li>
            <li className="nav-item">
                <a href="#product-tab-specification" className="nav-link">Specification</a>
            </li>
            <li className="nav-item">
                <a href="#product-tab-vendor" className="nav-link">Vendor Info</a>
            </li>
            <li className="nav-item">
                <a href="#product-tab-reviews" className="nav-link">Customer Reviews (3)</a>
            </li>
        </ul>
    )
}
