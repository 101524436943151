import React from 'react'

export default function FilterSidebar() {
    return (
        <aside className="sidebar shop-sidebar sticky-sidebar-wrapper sidebar-fixed">
            <div className="sidebar-overlay"></div>
            <a className="sidebar-close" href="shop-banner-sidebar.html#"><i className="close-icon"></i></a>

            <div className="sidebar-content scrollable">
                <div className="sticky-sidebar">
                    <div className="filter-actions">
                        <label>Filter :</label>
                        <a href="shop-banner-sidebar.html#" className="btn btn-dark btn-link filter-clean">Clean All</a>
                    </div>

                    <div className="widget widget-collapsible">
                        <h3 className="widget-title"><label>All Categories</label></h3>
                        <ul className="widget-body filter-items search-ul">
                            <li><a href="shop-banner-sidebar.html#">Accessories</a></li>
                            <li><a href="shop-banner-sidebar.html#">Babies</a></li>
                            <li><a href="shop-banner-sidebar.html#">Beauty</a></li>
                            <li><a href="shop-banner-sidebar.html#">Decoration</a></li>
                            <li><a href="shop-banner-sidebar.html#">Electronics</a></li>
                            <li><a href="shop-banner-sidebar.html#">Fashion</a></li>
                            <li><a href="shop-banner-sidebar.html#">Food</a></li>
                            <li><a href="shop-banner-sidebar.html#">Furniture</a></li>
                            <li><a href="shop-banner-sidebar.html#">Kitchen</a></li>
                            <li><a href="shop-banner-sidebar.html#">Medical</a></li>
                            <li><a href="shop-banner-sidebar.html#">Sports</a></li>
                            <li><a href="shop-banner-sidebar.html#">Watches</a></li>
                        </ul>
                    </div>

                    <div className="widget widget-collapsible">
                        <h3 className="widget-title"><label>Price</label></h3>
                        <div className="widget-body">
                            <ul className="filter-items search-ul">
                                <li><a href="shop-banner-sidebar.html#">$0.00 - $100.00</a></li>
                                <li><a href="shop-banner-sidebar.html#">$100.00 - $200.00</a></li>
                                <li><a href="shop-banner-sidebar.html#">$200.00 - $300.00</a></li>
                                <li><a href="shop-banner-sidebar.html#">$300.00 - $500.00</a></li>
                                <li><a href="shop-banner-sidebar.html#">$500.00+</a></li>
                            </ul>
                            <form className="price-range">
                                <input type="number" name="min_price" className="min_price text-center" placeholder="$min" /><span className="delimiter">-</span>
                                    <input type="number" name="max_price" className="max_price text-center" placeholder="$max" /><a href="shop-banner-sidebar.html#"
                                    className="btn btn-primary btn-rounded">Go</a>
                            </form>
                        </div>
                    </div>

                    <div className="widget widget-collapsible">
                        <h3 className="widget-title"><label>Size</label></h3>
                        <ul className="widget-body filter-items item-check mt-1">
                            <li><a href="shop-banner-sidebar.html#">Extra Large</a></li>
                            <li><a href="shop-banner-sidebar.html#">Large</a></li>
                            <li><a href="shop-banner-sidebar.html#">Medium</a></li>
                            <li><a href="shop-banner-sidebar.html#">Small</a></li>
                        </ul>
                    </div>

                    <div className="widget widget-collapsible">
                        <h3 className="widget-title"><label>Brand</label></h3>
                        <ul className="widget-body filter-items item-check mt-1">
                            <li><a href="shop-banner-sidebar.html#">Elegant Auto Group</a></li>
                            <li><a href="shop-banner-sidebar.html#">Green Grass</a></li>
                            <li><a href="shop-banner-sidebar.html#">Node Js</a></li>
                            <li><a href="shop-banner-sidebar.html#">NS8</a></li>
                            <li><a href="shop-banner-sidebar.html#">Red</a></li>
                            <li><a href="shop-banner-sidebar.html#">Skysuite Tech</a></li>
                            <li><a href="shop-banner-sidebar.html#">Sterling</a></li>
                        </ul>
                    </div>

                    <div className="widget widget-collapsible">
                        <h3 className="widget-title"><label>Color</label></h3>
                        <ul className="widget-body filter-items item-check mt-1">
                            <li><a href="shop-banner-sidebar.html#">Black</a></li>
                            <li><a href="shop-banner-sidebar.html#">Blue</a></li>
                            <li><a href="shop-banner-sidebar.html#">Brown</a></li>
                            <li><a href="shop-banner-sidebar.html#">Green</a></li>
                            <li><a href="shop-banner-sidebar.html#">Grey</a></li>
                            <li><a href="shop-banner-sidebar.html#">Orange</a></li>
                            <li><a href="shop-banner-sidebar.html#">Yellow</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    )
}
