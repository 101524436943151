/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BASE_URL } from '../../Constants';
import Header from '../../Header';
import Content from './Components/Content';
import TabFor from './Components/TabFor';
import TabOne from './Components/TabOne';
import TabThre from './Components/TabThre';
import TabTwo from './Components/TabTwo';
import VendorInfo from './Components/VendorInfo';
import MoreProducts from './MoreProducts';
import MoreProductsComponent from './MoreProductsComponent';
import RelatedProducts from './RelatedProducts';

export default function ProductDetails() {

    let { id } = useParams();
    console.log(id);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const [SuccessMessgae, setSuccessMessgae] = useState('Product Variation Updated');
    const [showMessage, setShowMessage] = useState(false);

    const [ProductData, setProductData] = useState([]);
    const [ImageSlider, setImageSlider] = useState([]);
    const [FrequentlyBought, setFrequentlyBought] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    function addtoWishlists(_id, el) {

        el.currentTarget.style.color = 'green';
        el.currentTarget.style.borderColor = 'green';

        let wislistItems = localStorage.getItem('wishlists');


        if(wislistItems) {
            let temp = JSON.parse(wislistItems);

            temp.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(temp));
        } else {
            let tempar = [];
            tempar.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(tempar));
        }

    }

    function addToCart(_id) {

        let cartListItems = localStorage.getItem('cartItems');

        if(cartListItems) {
            let temp = JSON.parse(cartListItems);

            let _obj = {
                _id: _id,
                qty: 1
            }

            temp.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(temp));
        } else {
            let tempar = [];
            let _obj = {
                _id: _id,
                qty: 1
            }
            tempar.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(tempar));
        }

    }

    function showSuccessMessage() {
        setShowMessage(true);
        setSuccessMessgae('Product Variation Updated');

        setTimeout(() => {
            setShowMessage(false);
            setSuccessMessgae('Product Variation Updated');
        }, 3000);
    }

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.history.scrollRestoration = 'manual';

        fetch( BASE_URL + "/API/V1/getProductDetails", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "productId": id,
                }
            )
        })
        .then(res => res.json())
        .then((result) => 
        {
                console.log("result", result[0].data[0].options);
                setItems(result);
                setProductData(result[0].data[0]);
                setImageSlider(result[0].images);
                setFrequentlyBought(result[0].frequentlyBought);
                setCategoryName(result[0].category[0].categoryName);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, [id]);

    if(isLoaded === false) {
        return (
            <div></div>
        )
    } else {
        return (
            <div>
                <Header isHome={false} />
                <main className="main mb-10 pb-1">
                    <nav className="breadcrumb-nav container">
                        <ul className="breadcrumb bb-no">
                            <li><a href="demo1.html">Home</a></li>
                            <li>Products</li>
                        </ul>
                        <ul className="product-nav list-style-none">
                            <li className="product-nav-prev">
                                <a href="product-default.html#">
                                    <i className="w-icon-angle-left"></i>
                                </a>
                                <span className="product-nav-popup">
                                    <img src={window.location.origin + "/assets/images/products/product-nav-prev.jpg"} alt="Product" width="110"
                                        height="110" />
                                    <span className="product-name">Soft Sound Maker</span>
                                </span>
                            </li>
                            <li className="product-nav-next">
                                <a href="product-default.html#">
                                    <i className="w-icon-angle-right"></i>
                                </a>
                                <span className="product-nav-popup">
                                    <img src={window.location.origin + "/assets/images/products/product-nav-next.jpg"} alt="Product" width="110"
                                        height="110" />
                                    <span className="product-name">Fabulous Sound Speaker</span>
                                </span>
                            </li>
                        </ul>
                    </nav>
    
                    <div className="page-content">
                        <div className="container">
                            <div className="row gutter-lg">
                                <div className="main-content">
                                    <div className="product product-single row">
                                        <div className="col-md-6 mb-6">
                                            <div className="product-gallery product-gallery-sticky">
                                                <div className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-no">
                                                    {ImageSlider.map((item, key) => {
                                                        return (
                                                            <figure key={key} className="product-image">
                                                                <img src={ window.location.origin + "/" + item.images}
                                                                    data-zoom-image={window.location.origin + "/" + item.images}
                                                                    alt={ProductData.productName} width="800" height="900" />
                                                            </figure>
                                                        )
                                                    })}
                                                </div>
                                                <div className="product-thumbs-wrap">
                                                    <div className="product-thumbs row cols-4 gutter-sm">
                                                        {ImageSlider.map((item, key) => {
                                                            return (
                                                                <div key={key} className="product-thumb active">
                                                                    <img src={window.location.origin + "/" + item.images}
                                                                        alt="Product Thumb" width="800" height="900" />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <button className="thumb-up disabled"><i className="w-icon-angle-left"></i></button>
                                                    <button className="thumb-down disabled"><i className="w-icon-angle-right"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4 mb-md-6">
                                            <div className="product-details" data-sticky-options="{'minWidth': 767}">
                                                <h2 className="product-title">{ProductData.productName}</h2>
                                                <div className="product-bm-wrapper">
                                                    <figure className="brand">
                                                        <img src={window.location.origin + "/assets/images/products/brand/brand-1.jpg"} alt="Brand"
                                                            width="102" height="48" />
                                                    </figure>
                                                    <div className="product-meta">
                                                        <div className="product-categories">
                                                            Category:
                                                            <span className="product-category"><a>{categoryName}</a></span>
                                                        </div>
                                                        <div className="product-sku">
                                                            SKU: <span>{ProductData.SKU}</span>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <hr className="product-divider" />
    
                                                <div className="product-price"><ins className="new-price">{ProductData.price}</ins></div>
    
                                                <div className="ratings-container">
                                                    <div className="ratings-full">
                                                        <span className="ratings" style={{ width: '80%' }}></span>
                                                        <span className="tooltiptext tooltip-top"></span>
                                                    </div>
                                                    <a href="#product-tab-reviews" className="rating-reviews scroll-to">(3
                                                        Reviews)</a>
                                                </div>
    
                                                <div className="product-short-desc">
                                                    <ul className="list-type-check list-style-none">
                                                        {ProductData.options.map((item, key)=> {
                                                            return (
                                                                <li key={key}>{item}.</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
    
                                                <hr className="product-divider" />
    
                                                <div className="product-form product-variation-form product-color-swatch">
                                                    <label>Color:</label>
                                                    <div className="d-flex align-items-center product-variations">
                                                        {ProductData.ProductColors.map((color, key) => {
                                                            return (
                                                                <button onClick={() => showSuccessMessage()} key={key} className="color" style={{ backgroundColor: color }}></button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="product-form product-variation-form product-size-swatch">
                                                    <label className="mb-1">Size:</label>
                                                    <div className="flex-wrap d-flex align-items-center product-variations">
                                                        
                                                        {ProductData.prroductVariation.map((size, key) => {
                                                            return (
                                                                <a onClick={() => showSuccessMessage()} className="size" key={key}>{size}</a>
                                                            )
                                                        })}
                                                        
                                                    </div>
                                                    <a href="#" className="product-variation-clean">Clean All</a>
                                                </div>
    
                                                <div className="product-variation-price">
                                                    <span></span>
                                                </div>
    
                                                <div className="fix-bottom product-sticky-content sticky-content">
                                                    <div className="product-form container">
                                                        <div className="product-qty-form">
                                                            <div className="input-group">
                                                                <input className="quantity form-control" type="number" min="1" max="10000000" />
                                                                <button className="quantity-plus w-icon-plus"></button>
                                                                <button className="quantity-minus w-icon-minus"></button>
                                                            </div>
                                                        </div>
                                                        <button onClick={() => addToCart(ProductData._id) } className="btn btn-primary btn-cart">
                                                            <i className="w-icon-cart"></i>
                                                            <span>Add to Cart</span>
                                                        </button>
                                                    </div>
                                                </div>

                                                {showMessage == true &&
                                                <div class="alert alert-icon alert-success alert-bg alert-inline">
                                                    <h4 class="alert-title">
                                                        <i class="fas fa-check"></i>{SuccessMessgae}
                                                    </h4>
                                                </div>        
                                                }
                                                                
                                                <div className="social-links-wrapper">
                                                    <div className="social-links">
                                                        <div className="social-icons social-no-color border-thin">
                                                            <a href="product-default.html#" className="social-icon social-facebook w-icon-facebook"></a>
                                                            <a href="product-default.html#" className="social-icon social-twitter w-icon-twitter"></a>
                                                            <a href="product-default.html#"
                                                                className="social-icon social-pinterest fab fa-pinterest-p"></a>
                                                            <a href="product-default.html#" className="social-icon social-whatsapp fab fa-whatsapp"></a>
                                                            <a href="product-default.html#"
                                                                className="social-icon social-youtube fab fa-linkedin-in"></a>
                                                        </div>
                                                    </div>
                                                    <span className="divider d-xs-show"></span>
                                                    <div className="product-link-wrapper d-flex">
                                                        <button onClick={(el) => addtoWishlists(ProductData._id, el) } className="btn-product-icon btn-wishlist w-icon-heart"
                                                        title="Add to wishlist"> </button>
                                                        {/* <a href="product-default.html#"
                                                            className="btn-product-icon btn-wishlist w-icon-heart"><span></span></a> */}
                                                        <a href="product-default.html#"
                                                            className="btn-product-icon btn-compare btn-icon-left w-icon-compare"><span></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frequently-bought-together mt-5">
                                        <h2 className="title title-underline">Frequently Bought Together</h2>
                                        <div className="bought-together-products row mt-8 pb-4">
    
                                            {FrequentlyBought.map((item, key) => {

                                                return (
                                                    <div key={key} className="product product-wrap text-center">
                                                        <figure className="product-media">
                                                            <img src={window.location.origin + "/" + item.images[0].images} alt="Product"
                                                                width="138" height="138" />
                                                            <div className="product-checkbox">
                                                                <input type="checkbox" className="custom-checkbox" id="product_check1"
                                                                    name="product_check1" />
                                                                <label></label>
                                                            </div>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h4 className="product-name">
                                                                <a href="product-default.html#">{item.productName}</a>
                                                            </h4>
                                                            <div className="product-price">{item.price}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            
                                            <div className="product-button">
                                                <div className="bought-price font-weight-bolder text-primary ls-50">$1,874.00</div>
                                                <div className="bought-count">For 3 items</div>
                                                <a href="cart.html" className="btn btn-dark btn-rounded">Add All To Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab tab-nav-boxed tab-nav-underline product-tabs">
                                        
                                        <VendorInfo />
    
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="product-tab-description">
                                                <TabOne />
                                            </div>
                                            <div className="tab-pane" id="product-tab-specification">
                                                <TabTwo />
                                            </div>
                                            <div className="tab-pane" id="product-tab-vendor">
                                                <TabThre />
                                            </div>
                                            <div className="tab-pane" id="product-tab-reviews">
                                                <TabFor />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <MoreProducts />        
    
                                    <RelatedProducts />
                                </div>
    
                                <aside className="sidebar product-sidebar sidebar-fixed right-sidebar sticky-sidebar-wrapper">
                                    <div className="sidebar-overlay"></div>
                                    <a className="sidebar-close" href="product-default.html#"><i className="close-icon"></i></a>
                                    <a href="product-default.html#" className="sidebar-toggle d-flex d-lg-none"><i className="fas fa-chevron-left"></i></a>
                                    <div className="sidebar-content scrollable">
                                        <div className="sticky-sidebar">
                                            
                                            <Content />
    
                                            <div className="widget widget-banner mb-9">
                                                <div className="banner banner-fixed br-sm">
                                                    <figure>
                                                        <img src={window.location.origin + "/assets/images/shop/banner3.jpg"} alt="Banner" width="266"
                                                            height="220" style={{ backgroundColor: '#1D2D44' }} />
                                                    </figure>
                                                    <div className="banner-content">
                                                        <div className="banner-price-info font-weight-bolder text-white lh-1 ls-25">
                                                            40<sup className="font-weight-bold">%</sup><sub
                                                                className="font-weight-bold text-uppercase ls-25">Off</sub>
                                                        </div>
                                                        <h4
                                                            className="banner-subtitle text-white font-weight-bolder text-uppercase mb-0">
                                                            Ultimate Sale</h4>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <MoreProductsComponent />
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}
