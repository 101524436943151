import React from 'react'

export default function HomeBigProductSlider() {

    const Data = [
        {
            id: '7da6b87dfsdfsd',
            name: 'Coat PoolComfort Jacket',
            price: '$150.00 - $180.00',
            reviewsCount: 3,
            variations: ['Extra Large', 'Large', 'Medium', 'Small'],
            images: [
                'assets/images/demos/demo1/products/1-1-600x675.jpg',
                'assets/images/demos/demo1/products/1-2-600x675.jpg',
                'assets/images/demos/demo1/products/1-3-600x675.jpg',
                'assets/images/demos/demo1/products/1-4-600x675.jpg',
            ]
        },
        {
            id: '7da6b87dfsew23dfsd',
            name: 'Beyond OTP Shirt',
            price: '$26.00',
            reviewsCount: 3,
            variations: ['Extra Large', 'Large', 'Medium', 'Small'],
            images: [
                'assets/images/demos/demo1/products/2-1-600x675.jpg',
                'assets/images/demos/demo1/products/2-2-600x675.jpg',
                'assets/images/demos/demo1/products/2-3-600x675.jpg',
                'assets/images/demos/demo1/products/2-4-600x675.jpg',
            ]
        }
    ];

    function ProductSliderComponent({ datas }) {
        return (
            <div className="product product-single row">
                <div className="col-md-6">
                    <div className="product-gallery product-gallery-vertical mb-0">
                        <div className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-no">
                            
                            {datas.images.map((images, key) => {
                                return (
                                    <figure key={key} className="product-image">
                                        <img src={images} key={key}
                                            data-zoom-image={images} alt="Product Descriptions" width="800" height="900" />
                                    </figure>
                                )
                            })}
                            
                        </div>
                        <div className="product-thumbs-wrap">
                            <div className="product-thumbs">

                                {datas.images.map((images, key) => {
                                    return (
                                        <div key={key} className="product-thumb active">
                                            <img src={images} alt="Product thumb" width="60" height="68" />
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="product-label-group">
                            <label className="product-label label-discount">25% Off</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="product-details scrollable">
                        <h2 className="product-title mb-1"><a href="product-default.html">{datas.name}</a></h2>

                        <hr className="product-divider" />

                        <div className="product-price"><ins className="new-price ls-50">{datas.price}</ins></div>

                        <div className="product-countdown-container flex-wrap">
                            <label className="mr-2 text-default">Offer Ends In:</label>
                            <div className="product-countdown countdown-compact"
                                data-until="2022, 12, 31" data-compact="true">
                                629 days, 11: 59: 52</div>
                        </div>

                        <div className="ratings-container">
                            <div className="ratings-full">
                                <span className="ratings" style={{ width: "80%" }}></span>
                                <span className="tooltiptext tooltip-top"></span>
                            </div>
                            <a href="demo1.html#" className="rating-reviews">({datas.reviewsCount} Reviews)</a>
                        </div>

                        <div className="product-form product-variation-form product-size-swatch mb-3">
                            <label className="mb-1">Size:</label>
                            <div className="flex-wrap d-flex align-items-center product-variations">
                                {datas.variations.map((variations, key) => {
                                    return (
                                        <a href="demo1.html#" key={key} className="size">{variations}</a>
                                    )
                                })}

                            </div>
                            <a href="demo1.html#" className="product-variation-clean">Clean All</a>
                        </div>

                        <div className="product-variation-price">
                            <span></span>
                        </div>

                        <div className="product-form pt-4">
                            <div className="product-qty-form mb-2 mr-2">
                                <div className="input-group">
                                    <input className="quantity form-control" type="number" min="1"
                                        max="10000000" />
                                    <button className="quantity-plus w-icon-plus"></button>
                                    <button className="quantity-minus w-icon-minus"></button>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-cart">
                                <i className="w-icon-cart"></i>
                                <span>Add to Cart</span>
                            </button>
                        </div>

                        <div className="social-links-wrapper mt-1">
                            <div className="social-links">
                                <div className="social-icons social-no-color border-thin">
                                    <a href="demo1.html#"
                                        className="social-icon social-facebook w-icon-facebook"> </a>
                                    <a href="demo1.html#"
                                        className="social-icon social-twitter w-icon-twitter"> </a>
                                    <a href="demo1.html#"
                                        className="social-icon social-pinterest fab fa-pinterest-p"> </a>
                                    <a href="demo1.html#"
                                        className="social-icon social-whatsapp fab fa-whatsapp"> </a>
                                    <a href="demo1.html#"
                                        className="social-icon social-youtube fab fa-linkedin-in"> </a>
                                </div>
                            </div>
                            <span className="divider d-xs-show"></span>
                            <div className="product-link-wrapper d-flex">
                                <a href="demo1.html#"
                                    className="btn-product-icon btn-wishlist w-icon-heart"> </a>
                                <a href="demo1.html#"
                                    className="btn-product-icon btn-compare btn-icon-left w-icon-compare"> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <div className="col-lg-9 mb-4">
            <div className="single-product h-100 br-sm">
                <h4 className="title-sm title-underline font-weight-bolder ls-normal">Deals Hot Of The Day</h4>
                <div className="owl-carousel owl-theme owl-nav-top owl-nav-lg row cols-1 gutter-no"
                    data-owl-options="{
                    'nav': true,
                    'dots': false,
                    'margin': 20,
                    'items': 1
                }">
                    {Data.map((item, key) => {
                        return (
                            <ProductSliderComponent key={key} datas={item} />
                        )
                    })}

                </div>
            </div>
        </div>

    )
}
