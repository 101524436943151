import React from 'react'

export default function Clients() {

    return (
        <>
            <h2 className="title title-underline mb-4 ls-normal appear-animate">Our Clients</h2>
            <div className="owl-carousel owl-theme brands-wrapper mb-9 row gutter-no cols-xl-6 cols-lg-5 cols-md-4 cols-sm-3 cols-2 appear-animate"
                data-owl-options="{
                'nav': false,
                'dots': false,
                'margin': 0,
                'responsive': {
                    '0': {
                        'items': 2
                    },
                    '576': {
                        'items': 3
                    },
                    '768': {
                        'items': 4
                    },
                    '992': {
                        'items': 5
                    },
                    '1200': {
                        'items': 6
                    }
                }
            }">
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/1.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/2.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/3.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/4.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/5.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/6.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/7.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/8.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/9.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/10.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
                <div className="brand-col">
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/11.png" alt="Brand" width="410" height="186" />
                    </figure>
                    <figure className="brand-wrapper">
                        <img src="assets/images/demos/demo1/brands/12.png" alt="Brand" width="410" height="186" />
                    </figure>
                </div>
            </div>
        </>
    )
}
