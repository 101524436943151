import React from 'react'
import { Link } from 'react-router-dom'

export default function ProductList(props) {

    function addtoWishlists(_id) {

        let wislistItems = localStorage.getItem('wishlists');


        if(wislistItems) {
            let temp = JSON.parse(wislistItems);

            temp.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(temp));
        } else {
            let tempar = [];
            tempar.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(tempar));
        }

    }

    function addToCart(_id) {

        let cartListItems = localStorage.getItem('cartItems');

        if(cartListItems) {
            let temp = JSON.parse(cartListItems);

            let _obj = {
                _id: _id,
                qty: 1
            }

            temp.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(temp));
        } else {
            let tempar = [];
            let _obj = {
                _id: _id,
                qty: 1
            }
            tempar.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(tempar));
        }

    }

    return (
        <div className="product-wrap">
            <div className="product text-center">
                <figure className="product-media">
                    <Link to={"productDetails/" + props._id}>
                        <img src={props.image} alt="Product"
                            width="300" height="338" />
                        <img src={props.image} alt="Product"
                            width="300" height="338" />
                    </Link>
                    <div className="product-action-vertical">
                        <button onClick={() => addToCart(props._id) } className="btn-product-icon btn-cart w-icon-cart"
                            title="Add to cart"> </button>
                        <button onClick={() => addtoWishlists(props._id) } className="btn-product-icon btn-wishlist w-icon-heart"
                            title="Add to wishlist"> </button>
                        <a href="demo1.html#" className="btn-product-icon btn-quickview w-icon-search"
                            title="Quickview"> </a>
                        <a href="demo1.html#" className="btn-product-icon btn-compare w-icon-compare"
                            title="Add to Compare"> </a>
                    </div>
                </figure>
                <div className="product-details">
                    <h4 className="product-name"><Link to={"productDetails/" + props._id}>{props.name}</Link></h4>
                    <div className="ratings-container">
                        <div className="ratings-full">
                            <span className="ratings" style={{ width: "60%" }} ></span>
                            <span className="tooltiptext tooltip-top"></span>
                        </div>
                        <Link to={"productDetails/" + props._id} className="rating-reviews">({props.reviews} Reviews)</Link>
                    </div>
                    <div className="product-price">
                        <ins className="new-price">${props.amount}.00</ins>
                    </div>
                </div>
            </div>
        </div>
    )
}
