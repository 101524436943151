import React, { useEffect, useState }  from 'react'
import { useLocation, useParams } from 'react-router';
import { BASE_URL } from '../../Constants'
import Header from '../../Header';
import CategoryLists from './Components/CategoryLists';
import FilterSidebar from './Components/FilterSidebar';
import Product from './Components/Product';

import shortid from "shortid";

export default function Listing() {
    
    function getRandomKey() {
        return shortid.generate();
    }

    let { id } = useParams();
    console.log(id);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [CategoryProducts, setItems] = useState([]);
    const [CategoryListsData, setCategoryList] = useState([]);

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.history.scrollRestoration = 'manual';

        fetch( BASE_URL + "/API/V1/getProductByCategory", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "categoryId": id,
                }
            )
        })
        .then(res => res.json())
        .then((result) => 
        {
                console.log("result", result);
                setItems(result[0].data);
                setCategoryList(result[0].category);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, [id]);

    return (
        <div>
            <Header isHome={false} />
            <main className="main">
                <nav className="breadcrumb-nav">
                    <div className="container">
                        <ul className="breadcrumb bb-no">
                            <li><a href="demo1.html">Home</a></li>
                            <li>Shop</li>
                        </ul>
                    </div>
                </nav>

                <div className="page-content">
                    <div className="container">
                        <div className="shop-default-banner banner d-flex align-items-center mb-5 br-xs" style={{ backgroundColor: '#FFC74E', backgroundImage: 'assets/images/shop/banner1.jpg' }}>
                            <div className="banner-content">
                                <h4 className="banner-subtitle font-weight-bold">Accessories Collection</h4>
                                <h3 className="banner-title text-white text-uppercase font-weight-bolder ls-normal">Smart Wrist
                                    Watches</h3>
                                <a href="shop-banner-sidebar.html" className="btn btn-dark btn-rounded btn-icon-right">Discover
                                    Now<i className="w-icon-long-arrow-right"></i></a>
                            </div>
                        </div>
                        
                        <CategoryLists key={getRandomKey()} CategoryListsData={CategoryListsData} />

                        <div className="shop-content row gutter-lg mb-10">
                            
                            <FilterSidebar />

                            <div className="main-content">
                                <nav className="toolbox sticky-toolbox sticky-content fix-top">
                                    <div className="toolbox-left">
                                        <a href="shop-banner-sidebar.html#" className="btn btn-primary btn-outline btn-rounded left-sidebar-toggle 
                                            btn-icon-left d-block d-lg-none"><i
                                                className="w-icon-category"></i><span>Filters</span></a>
                                        <div className="toolbox-item toolbox-sort select-box text-dark">
                                            <label>Sort By :</label>
                                            <select name="orderby" className="form-control">
                                                <option value="default">Default sorting</option>
                                                <option value="popularity">Sort by popularity</option>
                                                <option value="rating">Sort by average rating</option>
                                                <option value="date">Sort by latest</option>
                                                <option value="price-low">Sort by pric: low to high</option>
                                                <option value="price-high">Sort by price: high to low</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="toolbox-right">
                                        <div className="toolbox-item toolbox-show select-box">
                                            <select name="count" className="form-control">
                                                <option value="9">Show 9</option>
                                                <option value="12">Show 12</option>
                                                <option value="24">Show 24</option>
                                                <option value="36">Show 36</option>
                                            </select>
                                        </div>
                                        <div className="toolbox-item toolbox-layout">
                                            <a href="shop-banner-sidebar.html" className="icon-mode-grid btn-layout active">
                                                <i className="w-icon-grid"></i>
                                            </a>
                                            <a href="shop-list.html" className="icon-mode-list btn-layout">
                                                <i className="w-icon-list"></i>
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                                <div className="product-wrapper row cols-md-3 cols-sm-2 cols-2">
                                    {CategoryProducts.map((data, key) => {
                                        return (
                                            <Product data={data} key={key} />
                                        )
                                    })}

                                </div>

                                <div className="toolbox toolbox-pagination justify-content-between">
                                    <p className="showing-info mb-2 mb-sm-0">
                                        Showing<span>1-12 of 60</span>Products
                                    </p>
                                    <ul className="pagination">
                                        <li className="prev disabled">
                                            <a href="shop-banner-sidebar.html#" aria-label="Previous" tabIndex="-1" aria-disabled="true">
                                                <i className="w-icon-long-arrow-left"></i>Prev
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="shop-banner-sidebar.html#">1</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="shop-banner-sidebar.html#">2</a>
                                        </li>
                                        <li className="next">
                                            <a href="shop-banner-sidebar.html#" aria-label="Next">
                                                Next<i className="w-icon-long-arrow-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
