import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../Constants';
// import  dataProducts from '../Jsons/BestSellerData.json';

function HomeBestSellers(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch( BASE_URL + "/API/V1/getBestSellersHome", {
            method: 'POST',
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("result", result);
                setIsLoaded(true);
                setItems(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])

    function ProductComponentsWidgets({ data }) {
        return (
            <div className="product product-widget bb-no">
                <figure className="product-media">
                    <Link to={"productDetails/" + data._id}>
                        <img src={window.location.origin + "/" + data.image} alt="Product"
                            width="105" height="118" />
                    </Link>
                </figure>
                <div className="product-details">
                    <h4 className="product-name">
                        <Link to={"productDetails/" + data._id}>{data.name}</Link>
                    </h4>
                    <div className="ratings-container">
                        <div className="ratings-full">
                            <span className="ratings" style={{ width: "60%" }} ></span>
                            <span className="tooltiptext tooltip-top"></span>
                        </div>
                    </div>
                    <div className="product-price">
                        <ins className="new-price">{data.price}</ins>
                        <del className="old-price">{data.oldPrice}</del>
                    </div>
                </div>
            </div>
        )
    }

    function ProductWidgetWrap({ items }) {
        return (
            <div className="product-widget-wrap">
                {items.products.map((product, key) => {
                    return (
                        <ProductComponentsWidgets key={key} data={product} />
                    )
                })}
            </div>
        )
    }

    if(isLoaded === false) {
        return (
            <div>
                Loading...
            </div>
        )
    } else {
        return (
            <div className="col-lg-3 mb-4">
                <div className="widget widget-products widget-products-bordered h-100">
                    <div className="widget-body br-sm h-100">
                        <h4 className="title-sm title-underline font-weight-bolder ls-normal mb-2">Top 20 Best
                            Seller</h4>
                        <div className="owl-carousel owl-theme owl-nav-top row cols-lg-1 cols-md-3"
                            data-owl-options="{
                            'nav': true,
                            'dots': false,
                            'margin': 20,
                            'responsive': {
                                '0': {
                                    'items': 1
                                },
                                '576': {
                                    'items': 2
                                },
                                '768': {
                                    'items': 3
                                },
                                '992': {
                                    'items': 1
                                }
                            }
                        }">
                            
                        {items.map((items, key) => {
                            return (
                                <ProductWidgetWrap items={items} key={key} />
                            )
                        })}
    
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeBestSellers;