import React, { useEffect, useState }  from 'react'
import { useLocation, useParams } from 'react-router';
import { BASE_URL } from '../../Constants'
import Header from '../../Header';
import CategoryLists from './Components/CategoryLists';
import FilterSidebar from './Components/FilterSidebar';
import Product from './Components/Product';

import shortid from "shortid";
import Categories from '../../Components/Categories';
import HomeBigProductSlider from '../../Components/HomeBigProductSlider';
import HomeBestSellers from '../../Components/HomeBestSellers';
import HomeOfferWidget from '../../Components/HomeOfferWidget';

export default function ListCategories() {
    
    function getRandomKey() {
        return shortid.generate();
    }

    let { id } = useParams();
    console.log(id);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [CategoryProducts, setItems] = useState([]);
    const [CategoryListsData, setCategoryList] = useState([]);

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.history.scrollRestoration = 'manual';

        fetch( BASE_URL + "/API/V1/getProductByCategory", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "categoryId": id,
                }
            )
        })
        .then(res => res.json())
        .then((result) => 
        {
                console.log("result", result);
                setItems(result[0].data);
                setCategoryList(result[0].category);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, [id]);

    return (
        <div>
            <Header isHome={false} />
            <main className="main">
                <nav className="breadcrumb-nav">
                    <div className="container">
                        <ul className="breadcrumb bb-no">
                            <li><a href="demo1.html">Home</a></li>
                            <li>Shop</li>
                        </ul>
                    </div>
                </nav>

                <div className="page-content">
                    <div className="container">
                        <div className="shop-default-banner banner d-flex align-items-center mb-5 br-xs" style={{ backgroundColor: '#FFC74E', backgroundImage: 'assets/images/shop/banner1.jpg' }}>
                            <div className="banner-content">
                                <h4 className="banner-subtitle font-weight-bold">Accessories Collection</h4>
                                <h3 className="banner-title text-white text-uppercase font-weight-bolder ls-normal">Smart Wrist
                                    Watches</h3>
                                <a href="shop-banner-sidebar.html" className="btn btn-dark btn-rounded btn-icon-right">Discover
                                    Now<i className="w-icon-long-arrow-right"></i></a>
                            </div>
                        </div>
                        
                        <CategoryLists key={getRandomKey()} CategoryListsData={CategoryListsData} />

                    </div>
                </div>
            </main>
        </div>
    )
}
