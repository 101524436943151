/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from 'react-router-dom'

export default function Product({ data }) {

    function addtoWishlists(_id) {

        let wislistItems = localStorage.getItem('wishlists');


        if(wislistItems) {
            let temp = JSON.parse(wislistItems);

            temp.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(temp));
        } else {
            let tempar = [];
            tempar.push(_id);
            localStorage.setItem('wishlists', JSON.stringify(tempar));
        }

    }

    function addToCart(_id) {

        let cartListItems = localStorage.getItem('cartItems');

        if(cartListItems) {
            let temp = JSON.parse(cartListItems);

            let _obj = {
                _id: _id,
                qty: 1
            }

            temp.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(temp));
        } else {
            let tempar = [];
            let _obj = {
                _id: _id,
                qty: 1
            }
            tempar.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(tempar));
        }

    }

    return (
        <div className="product-wrap">
            <div className="product text-center">
                <figure className="product-media">
                    <Link to={ "/productDetails/" + data._id } >

                        <img src={window.location.origin + "/" + data.images[0].images} alt="Product" width="300" height="338" />
                        
                    </Link>
                    <div className="product-action-horizontal">
                    <button onClick={() => addToCart(data._id) } className="btn-product-icon btn-cart w-icon-cart"
                            title="Add to cart"> </button>
                        <button onClick={() => addtoWishlists(data._id) } className="btn-product-icon btn-wishlist w-icon-heart"
                            title="Add to wishlist"> </button>
                        <a href="shop-banner-sidebar.html#" className="btn-product-icon btn-compare w-icon-compare"
                            title="Compare"></a>
                        <a href="shop-banner-sidebar.html#" className="btn-product-icon btn-quickview w-icon-search"
                            title="Quick View"></a>
                    </div>
                </figure>
                <div className="product-details">
                    <div className="product-cat">
                        <a href="#">{data.category}</a>
                    </div>
                    <h3 className="product-name">
                        <Link to={ "/productDetails/" + data._id } >{data.productName}</Link>
                    </h3>
                    <div className="ratings-container">
                        <div className="ratings-full">
                            <span className="ratings" style={{ width: '100%' }}></span>
                            <span className="tooltiptext tooltip-top"></span>
                        </div>
                        <Link to={ "/productDetails/" + data._id } className="rating-reviews">(3 reviews)</Link>
                    </div>
                    <div className="product-pa-wrapper">
                        <div className="product-price">
                           {data.price}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
