import React from 'react'
import { Link } from 'react-router-dom';

export default function CategoryLists({ CategoryListsData }) {

    const CategoryLists = [
        {
            "icon": "assets/images/categories/category-4.jpg",
            "name": "Sports"
        },
        {
            "icon": "assets/images/categories/category-5.jpg",
            "name": "Babies"
        },
        {
            "icon": "assets/images/categories/category-6.jpg",
            "name": "Sneakers"
        },
        {
            "icon": "assets/images/categories/category-7.jpg",
            "name": "Cameras"
        },
        {
            "icon": "assets/images/categories/category-8.jpg",
            "name": "Games"
        },
        {
            "icon": "assets/images/categories/category-9.jpg",
            "name": "Kitchen"
        },
        {
            "icon": "assets/images/categories/category-20.jpg",
            "name": "Watches"
        },
        {
            "icon": "assets/images/categories/category-21.jpg",
            "name": "Clothes"
        }
    ]

    return (
        <div className="shop-default-category category-ellipse-section mb-6">
            <div className="owl-carousel owl-theme row gutter-lg cols-xl-8 cols-lg-7 cols-md-6 cols-sm-4 cols-xs-3 cols-2"
                data-owl-options="{
                'nav': false,
                'dots': true,
                'margin': 20,
                'responsive': {
                    '0': {
                        'items': 2
                    },
                    '480': {
                        'items': 3
                    },
                    '576': {
                        'items': 4
                    },
                    '768': {
                        'items': 6
                    },
                    '992': {
                        'items': 7
                    },
                    '1200': {
                        'items': 8,
                        'margin': 30
                    }
                }
            }">
                
                
                {CategoryListsData.map((data, key) => {
                    return (
                        <div key={key} className="category-wrap">
                            <div className="category category-ellipse">
                                <figure className="category-media">
                                    <Link to={ "/productCategories/" + data._id }>
                                        <img src={ window.location.origin + "/" + data.categoryIcon} alt="Categroy"
                                            width="190" height="190" style={{ height: 128, objectFit: 'fill', backgroundColor: '#E4E4E4' }} />
                                    </Link>
                                </figure>
                                <div className="category-content">
                                    <h4 className="category-name">
                                        <Link to={ "/productCategories/" + data._id }>{data.categoryName}</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )
                })}
                

            </div>
        </div>
    )
}
