import React from 'react'

export default function MoreProductsComponent() {

    const MoreProductComponent = [
        {
            "image": "assets/images/shop/13.jpg",
            "name": "Smart Watch",
            "price": "$80.00 - $90.00"
        },
        {
            "image": "assets/images/shop/14.jpg",
            "name": "Sky Medical Facility",
            "price": "$58.00"
        },
        {
            "image": "assets/images/shop/15.jpg",
            "name": "Black Stunt Motor",
            "price": "$80.00 - $90.00"
        },
        {
            "image": "assets/images/shop/16.jpg",
            "name": "Skate Pan",
            "price": "$80.00 - $90.00"
        },
        {
            "image": "assets/images/shop/17.jpg",
            "name": "Modern Cooker",
            "price": "$80.00 - $90.00"
        },
        {
            "image": "assets/images/shop/18.jpg",
            "name": "CT Machine",
            "price": "$80.00 - $90.00"
        }
    ]

    function MorePrdComponent({ item }) {
        return (
            <div className="product product-widget">
                <figure className="product-media">
                    <a href="product-default.html#">
                        <img src={window.location.origin + "/" + item.image} alt="Product" width="100" height="113" />
                    </a>
                </figure>
                <div className="product-details">
                    <h4 className="product-name">
                        <a href="product-default.html#">{item.name}</a>
                    </h4>
                    <div className="ratings-container">
                        <div className="ratings-full">
                            <span className="ratings" style={{ width: '100%' }}></span>
                            <span className="tooltiptext tooltip-top"></span>
                        </div>
                    </div>
                    <div className="product-price">{item.price}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="widget widget-products">
            <div className="title-link-wrapper mb-2">
                <h4 className="title title-link font-weight-bold">More Products</h4>
            </div>

            <div className="owl-carousel owl-theme owl-nav-top" data-owl-options="{
                'nav': true,
                'dots': false,
                'items': 1,
                'margin': 20
            }">
                <div className="widget-col">
                    {MoreProductComponent.map((item, key) => {
                        return (
                            <MorePrdComponent key={key} item={item} />
                        )
                    })}

                </div>
            </div>

        </div>
    )
}
