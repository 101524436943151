import React, { useEffect, useState }  from 'react';
import Header from '../../Header'

import shortid from "shortid";
import { BASE_URL } from '../../Constants';
import { Link } from 'react-router-dom';

export default function Wishlist() {
    
    function getRandomKey() {
        return shortid.generate();
    }

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [Wishlisted, setItems] = useState([]);
    const [wislistItems, setWislistItems] = useState([]);

    function removeList(_id) {
        let newarray = JSON.parse(wislistItems);

        let filteredarray = newarray.filter((element) => element !=  _id);
        console.log("filteredarray", filteredarray);
        setWislistItems(filteredarray);

        let temp = JSON.parse(wislistItems);

        temp.pop(_id);
        localStorage.setItem('wishlists', JSON.stringify(filteredarray));

    }

    useEffect(() => {

        setWislistItems(localStorage.getItem('wishlists'));

        let wishlisted = localStorage.getItem('wishlists');

        console.log("wishlisted", wishlisted);

        // window.scrollTo({ top: 0, behavior: 'smooth' });
        // window.history.scrollRestoration = 'manual';

        fetch( BASE_URL + "/API/V1/getWishlistedData", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "wishlistIds": JSON.parse(wishlisted),
                }
            )
        })
        .then(res => res.json())
        .then((result) => 
        {
                setItems(result);
                setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            setIsLoaded(true);
            setError(error);
        })
    }, [wislistItems, error]);

    function addToCart(_id, el) {
        el.currentTarget.style.backgroundColor = 'green';
        el.currentTarget.style.borderColor = 'green';
        el.currentTarget.innerHTML = 'Added';

        let cartListItems = localStorage.getItem('cartItems');

        if(cartListItems) {
            let temp = JSON.parse(cartListItems);

            let _obj = {
                _id: _id,
                qty: 1
            }

            temp.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(temp));
        } else {
            let tempar = [];
            let _obj = {
                _id: _id,
                qty: 1
            }
            tempar.push(_obj);
            localStorage.setItem('cartItems', JSON.stringify(tempar));
        }

    }

    function WishlistComponent({ items }) {
        return (
            <tr>
                <td className="product-thumbnail">
                    <div className="p-relative">
                        <Link to={ "/productDetails/" + items._id } >
                            <figure>
                                <img src={window.location.origin + "/" + items.images[0].images}  alt="product" width="300"
                                    height="338" />
                            </figure>
                        </Link>
                        <button type="button" onClick={() => removeList(items._id) } className="btn btn-close"><i
                                className="fas fa-times"></i></button>
                    </div>
                </td>
                <td className="product-name">
                    <Link to={ "/productDetails/" + items._id } >
                        {items.productName}
                    </Link>
                </td>
                <td className="product-price"><ins className="new-price">{items.price}</ins></td>
                <td className="product-stock-status">
                    <span className="wishlist-in-stock">In Stock</span>
                </td>
                <td className="wishlist-action">
                    <div className="d-lg-flex">
                        <a href="#"
                            className="btn btn-quickview btn-outline btn-default btn-rounded btn-sm mb-2 mb-lg-0">Quick
                            View</a>
                        <button onClick={(el) => addToCart(items._id, el)} className="btn btn-dark btn-cart btn-rounded btn-sm ml-lg-2 btn-wishlist-cart btn-cart">Add to
                            cart</button>
                    </div>
                </td>
            </tr>
        )
    }

    return (
        <div>
            <Header isHome={false} />
            <main className="main wishlist-page">
                <div className="page-header">
                    <div className="container">
                        <h1 className="page-title mb-0">Wishlist</h1>
                    </div>
                </div>
             
                <nav className="breadcrumb-nav mb-10">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a href="demo1.html">Home</a></li>
                            <li>Wishlist</li>
                        </ul>
                    </div>
                </nav>
                
                <div className="page-content">
                    <div className="container">
                        <h3 className="wishlist-title">My wishlist</h3>
                        <table className="shop-table wishlist-table">
                            <thead>
                                <tr>
                                    <th className="product-name"><span>Product</span></th>
                                    <th></th>
                                    <th className="product-price"><span>Price</span></th>
                                    <th className="product-stock-status"><span>Stock Status</span></th>
                                    <th className="wishlist-action">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {Wishlisted.map((item, key) => {
                                    return (
                                        <WishlistComponent items={item} key={key} />
                                    )
                                })}

                            </tbody>
                        </table>
                        <div className="social-links">
                            <label>Share On:</label>
                            <div className="social-icons social-no-color border-thin">
                                <a href="wishlist.html#" className="social-icon social-facebook w-icon-facebook"></a>
                                <a href="wishlist.html#" className="social-icon social-twitter w-icon-twitter"></a>
                                <a href="wishlist.html#" className="social-icon social-pinterest w-icon-pinterest"></a>
                                <a href="wishlist.html#" className="social-icon social-email far fa-envelope"></a>
                                <a href="wishlist.html#" className="social-icon social-whatsapp fab fa-whatsapp"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
