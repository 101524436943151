import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from './Pages/About';
import Header from './Header';
import Footer from './Footer';
import Stickyfooter from './Stickyfooter';
import MobileMenu from './MobileMenu';
import StartQukView from './StartQukView';
import ProductDetails from './Pages/Products/ProductDetails';
import Listing from './Pages/CategoriesList/Listing';
import CartLists from './Pages/Cart/CartLists';
import Checkout from './Pages/Checkout/Checkout';
import Wishlist from './Pages/Wishlist/Wishlist';
import Contact from './Pages/Contact';
import SearchIndex from './Pages/Search/SearchIndex';
import ListCategories from './Pages/CategoriesList/ListCategories';
import Success from './Pages/Checkout/Success';
  
export default function Router() {
    return (
        <BrowserRouter>
            <div >
                <div className="page-wrapper">
                    <Switch>
                        <Route exact path="/" component={App} />
                        <Route path="/about-us" component={About} />
                        <Route path="/productDetails/:id" component={ProductDetails} />
                        <Route path="/productCategories/:id" component={Listing} />
                        <Route path="/productCategories" component={ListCategories} />
                        <Route path="/carts" component={CartLists} />
                        <Route path="/checkout" component={Checkout} />
                        <Route path="/wishlist" component={Wishlist} />
                        <Route path="/contact-us" component={Contact} />
                        <Route path="/search" component={SearchIndex} />
                        <Route path="/success" component={Success} />
                    </Switch>
                    
                    <Footer />
                </div>
                
                <Stickyfooter />
                <MobileMenu />
                <StartQukView />
            </div>
        </BrowserRouter>
    )
}
