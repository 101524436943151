import Header from './Header';
import Main from './Main';
import shortid from "shortid";


function App() {

  function getRandomKey() {
    return shortid.generate();
  }

  return (
    <>
      <Header key={getRandomKey()} isHome={true} />
      <Main key={getRandomKey()} />
    </>
  );
}

export default App;
