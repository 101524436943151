import React from 'react'

export default function Blogs() {

    const BlogsData = [
        {
            "image": "assets/images/demos/demo1/blogs/1.jpg",
            "author": "John Doe",
            "datePublished": "03.05.2021",
            "name": "Aliquam tincidunt mauris eurisus",
        },{
            "image": "assets/images/demos/demo1/blogs/2.jpg",
            "author": "John Doe",
            "datePublished": "03.05.2021",
            "name": "Aliquam tincidunt mauris eurisus",
        },
        {
            "image": "assets/images/demos/demo1/blogs/3.jpg",
            "author": "John Doe",
            "datePublished": "03.05.2021",
            "name": "Aliquam tincidunt mauris eurisus",
        },
        {
            "image": "assets/images/demos/demo1/blogs/4.jpg",
            "author": "John Doe",
            "datePublished": "03.05.2021",
            "name": "Aliquam tincidunt mauris eurisus",
        }
    ]

    function BlogsComponent({ data }) {
        return (
            <div className="post text-center overlay-zoom">
                <figure className="post-media br-sm">
                    <a href="#">
                        <img src={data.image} alt="Post" width="280" height="180"
                            style={{ backgroundColor: "#4b6e91" }} />
                    </a>
                </figure>
                <div className="post-details">
                    <div className="post-meta">
                        by <a href="demo1.html#" className="post-author">{data.author}</a>
                        - <a href="demo1.html#" className="post-date mr-0">{data.datePublished}</a>
                    </div>
                    <h4 className="post-title"><a href="#">{data.name}</a>
                    </h4>
                    <a href="#" className="btn btn-link btn-dark btn-underline">Read More<i
                            className="w-icon-long-arrow-right"></i></a>
                </div>
            </div>
        )
    }

    return (
        <div className="post-wrapper appear-animate mb-4">
            <div className="title-link-wrapper pb-1 mb-4">
                <h2 className="title ls-normal mb-0">From Our Blog</h2>
                <a href="blog-listing.html" className="font-weight-bold font-size-normal">View All Articles</a>
            </div>
            <div className="owl-carousel owl-theme row cols-lg-4 cols-md-3 cols-sm-2 cols-1" data-owl-options="{
                'items': 4,
                'nav': false,
                'dots': true,
                'loop': false,
                'margin': 20,
                'responsive': {
                    '0': {
                        'items': 1
                    },
                    '576': {
                        'items': 2
                    },
                    '768': {
                        'items': 3
                    },
                    '992': {
                        'items': 4,
                        'dots': false
                    }
                }
            }">
                
                {BlogsData.map((items, key) => {
                    return (
                        <BlogsComponent data={items} key={key} />
                    )
                })}

            </div>
        </div>
    )
}
